import { Checkbox, FormControlLabel, Paper, Radio } from "@mui/material";
import React, { useState } from "react";
import parser from "html-react-parser";

const QuizStartAnswerList = ({
  answers,
  q,
  possibleAnswer,
  checkedPossibleAnswer,
}) => {
  const [selectedUid, setSelectedUid] = useState();

  const handlePossibleAnswer = (auuid, quuid) => {
    setSelectedUid(auuid);
    possibleAnswer(auuid, quuid);
  };

  const handleCheckedPossibleAnswer = (checked, auuid, quuid) => {
    checkedPossibleAnswer(checked, auuid, quuid);
  };

  return (
    <>
      {answers.map((a, i) => (
        <Paper key={i} sx={{ padding: 2, ml: 5, mr: 5, mb: 1 }} elevation={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              {q.qnType === 1 ? (
                <FormControlLabel
                  control={
                    <Radio
                      name={`possibleAnswer${q.uuid}`}
                      checked={selectedUid === a.uuid}
                      value={a.uuid}
                      onChange={(e) => handlePossibleAnswer(a.uuid, q.uuid)}
                    />
                  }
                  label={`${parser(a.possibleAnswer)}`}
                />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleCheckedPossibleAnswer(
                          e.target.checked,
                          a.uuid,
                          q.uuid
                        )
                      }
                    />
                  }
                  label={`${parser(a.possibleAnswer)}`}
                />
              )}
            </div>
          </div>
        </Paper>
      ))}
    </>
  );
};

export default QuizStartAnswerList;
