import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  Delete,
  DeviceHubOutlined,
  DeviceThermostat,
  Token,
} from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ActionBtn from "../../components/action-btn/ActionBtn";
import DataTableUi from "../../components/data-table/DataTableUi";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import Widget from "../../components/widget/Widget";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading, systemInfoVar } from "../../store/cache";
import { promptBox } from "../../utils/helpers";
import { GET_SYSTEM_INFO } from "../login/graphQL";
import { SAVE_PUSH_NOTY } from "../settings/graphQL";
import { DELETE_REGISTRED_TOKEN, GET_ALL_REGISTERED_TOKEN } from "./graphQLs";

export const PushTokens = () => {
  const [tokenVisible, setTokenVisible] = useState(false);
  const [token, setToken] = useState();

  const { data, loading } = useQuery(GET_SYSTEM_INFO);

  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    if (data) {
      setChecked(data.getSystemInfoSettings.isPushNotificationEnabled);
    }
  }, [data]);

  const { mutation: savePushNotificationEnabled } = useGraphQLMutator(
    SAVE_PUSH_NOTY,
    "savePushNotificationEnabled",
    [GET_ALL_REGISTERED_TOKEN],
    () => {
      isLoading(false);
    },
    `Successfully saved`,
    () => {
      isLoading(false);
    }
  );

  const updatePushNotification = () => {
    isLoading(true);
    if (data) {
      savePushNotificationEnabled({
        variables: {
          enabled: !data.getSystemInfoSettings.isPushNotificationEnabled,
        },
      });
    }
  };

  const { mutation: deletePushNotificationToken } = useGraphQLMutator(
    DELETE_REGISTRED_TOKEN,
    "deletePushNotificationToken",
    [GET_ALL_REGISTERED_TOKEN],
    () => {
      isLoading(false);
    }
  );

  const deleteToken = (uid) => {
    promptBox(() => {
      isLoading(true);
      deletePushNotificationToken({
        variables: {
          uuid: uid,
        },
      });
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      render: (_, record) => {
        return (
          <ActionBtn
            icon={<Token color="success" />}
            onClickIcon={() => {
              setTokenVisible(true);
              setToken(record.token);
            }}
            title={`Device Token`}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <Space>
            <ActionBtn
              title="Delete Token"
              onClickIcon={() => deleteToken(rec.uuid)}
              icon={<Delete color="error" fontSize="inherit" />}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalContainerUi
        onCancel={() => setTokenVisible(false)}
        title="Device Token"
        top={300}
        visible={tokenVisible}
      >
        {token}
      </ModalContainerUi>

      <TitleBoxUi title={`Registered Token`} icon={<Token />}>
        {loading && <LinearProgress />}
        {data && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  updatePushNotification();
                  setChecked(event.target.checked);
                }}
                checked={checked}
              />
            }
            label="Push Notification Enabled"
          />
        )}
      </TitleBoxUi>

      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-12">
          <div className="col-md-3">
            <Widget
              title={`Device Registered`}
              queryName="getAllRegTokens"
              query={GET_ALL_REGISTERED_TOKEN}
              icon={<Token style={{ fontSize: 120 }} color="success" />}
              count
            />
          </div>
        </div>
      </div>

      <DataTableUi
        columns={columns}
        query={GET_ALL_REGISTERED_TOKEN}
        queryName="getAllRegTokens"
      />
    </>
  );
};
