import React, { useState } from "react";
import { Space, Typography } from "antd";
import ActionBtn from "../../components/action-btn/ActionBtn";
import {
  Add,
  ApartmentRounded,
  Delete,
  Edit,
  RemoveRedEye,
} from "@mui/icons-material";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { Button, Paper, TextField } from "@mui/material";
import { PRIMARY_COLOR } from "../../utils/constants";
import DataTableUi from "../../components/data-table/DataTableUi";
import {
  CREATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
  UPDATE_DEPARTMENT,
} from "./graphQL";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { useForm } from "react-hook-form";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import { promptBox } from "../../utils/helpers";
import { isLoading } from "../../store/cache";

const Departments = () => {
  const [modalTitle, setModalTitle] = useState("Add Department");

  const {
    mutation: createDepartment,
    submiting,
    visible,
    setVisible,
    setSubmitting,
    setEditing,
    editing,
    setUuid,
    uuid,
  } = useGraphQLMutator(
    CREATE_DEPARTMENT,
    "createDepartment",
    [GET_ALL_DEPARTMENTS],
    () => {},
    null,
    () => {
      reset();
    }
  );

  const { mutation: deleteDeptMutation } = useGraphQLMutator(
    DELETE_DEPARTMENT,
    "deleteDepartment",
    [GET_ALL_DEPARTMENTS],
    () => {
      isLoading(false);
    },
    `Successfully deleted!`
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const showModal = () => {
    setVisible(true);
    reset();
  };

  const handleEditDept = (dept) => {
    setVisible(true);
    setModalTitle("Edit Department");
    setEditing(true);
    setValue("name", dept.name);
    setUuid(dept.uuid);
  };

  const handleDeleteDept = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteDeptMutation({
        variables: {
          uid: uuid,
        },
      });
    });
  };

  const { mutation: updateDepartment } = useGraphQLMutator(
    UPDATE_DEPARTMENT,
    "updateDepartment",
    [GET_ALL_DEPARTMENTS],
    () => {
      setVisible(false);
      isLoading(false);
      setSubmitting(false);
    }
  );

  const onSubmit = async (data) => {
    setSubmitting(true);

    if (editing) {
      updateDepartment({
        variables: {
          uuid,
          input: {
            ...data,
          },
        },
      });
    } else {
      createDepartment({
        variables: {
          input: {
            ...data,
          },
        },
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <ActionBtn
            title="Edit Department"
            onClickIcon={() => handleEditDept(record)}
            icon={<Edit color="info" fontSize="inherit" />}
          />
          <ActionBtn
            title="Delete Department"
            onClickIcon={() => handleDeleteDept(record.uuid)}
            icon={<Delete color="error" fontSize="inherit" />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <TitleBoxUi title="Manage Departments" icon={<ApartmentRounded />}>
        <Button
          onClick={showModal}
          variant="contained"
          sx={{ background: PRIMARY_COLOR }}
          startIcon={<Add />}
        >
          Add Departments
        </Button>
      </TitleBoxUi>

      <Paper
        sx={{
          mt: 2,
          padding: 2,
        }}
      >
        <ModalContainerUi
          top={null}
          title={modalTitle}
          visible={visible}
          onCancel={() => {
            setVisible(false); /*reset();*/
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Typography>Name</Typography>
              <TextField
                margin="normal"
                fullWidth
                label="Name"
                name="name"
                {...register("name", { required: "Name is required." })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </div>

            <ModalFooter
              loading={submiting}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </form>
        </ModalContainerUi>
        <DataTableUi
          columns={columns}
          query={GET_ALL_DEPARTMENTS}
          queryName="getAllDepartments"
        />
      </Paper>
    </>
  );
};

export default Departments;
