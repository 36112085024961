import { Alert } from "@mui/material";
import React from "react";

const NoAccess = () => {
  return (
    <>
      <Alert severity="error">
        No access - Navigate to menu links on your left only
      </Alert>
    </>
  );
};

export default NoAccess;
