import { InputWrapper } from "@mantine/core";
import RichTextEditor, { Editor } from "@mantine/rte";
import React, { useEffect, useRef, useState } from "react";

const EditBox = ({ description, setError, error, getEditorContent }) => {
  const [value, setValue] = useState(description);
  const editorRef = useRef(Editor);

  useEffect(() => {
    if (description) {
      //editorRef.current.editor.root.innerHTML = description;
      setValue(description);
    }

    return () => {
      setValue("");
    };
  }, [description]);

  useEffect(() => {
    if (value) {
      getEditorContent(value);
    }
  }, [value, getEditorContent]);

  return (
    <>
      <InputWrapper error={error}>
        <RichTextEditor
          ref={editorRef}
          value={value}
          onChange={(e) => {
            setValue(e);
            setError(null);
          }}
          sx={{
            border: error ? "2px solid red" : "",
            padding: 2,
          }}
        />
      </InputWrapper>
    </>
  );
};

export default EditBox;
