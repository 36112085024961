import { Tabs } from "antd";
import { SettingsApplications } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React from "react";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import GeneralSettings from "./general/GeneralSettings";
import CompanySettings from "./company/CompanySettings";
import NotificationSettings from "./notification/NotificationSettings";
import ReminderSettings from "./reminder/ReminderSettings";
import { PushTokens } from "../push-tokens/PushTokens";
import SystemErrors from "../system-errors/SystemErrors";
import { QuizSettings } from "./quiz/QuizSettings";

const Settings = () => {
  return (
    <>
      <TitleBoxUi
        title={`Settings`}
        icon={<SettingsApplications />}
      ></TitleBoxUi>

      <Paper sx={{ mt: 2, padding: 2 }}>
        <Tabs defaultActiveKey="1" tabPosition="left">
          <Tabs.TabPane tab="General Settings" key="1">
            <GeneralSettings />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Company Details" key="2">
            <CompanySettings />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Notification Settings" key="3">
            <NotificationSettings />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Quiz Reminder Settings" key="4">
            <ReminderSettings />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Devices Registered" key="5">
            <PushTokens />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Quiz Settings" key="6">
            <QuizSettings />
          </Tabs.TabPane>
        </Tabs>
      </Paper>
    </>
  );
};

export default Settings;
