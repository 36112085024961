import {ACCESS_TOKEN, MY_PERMISSIONS} from "../utils/constants";
import {isLoggedInVar} from "../store/cache";
import {useApolloClient} from "@apollo/client";
import {useCallback} from "react";


export default function useLogout() {
    const client = useApolloClient();

    const logout = useCallback(() => {
        client.clearStore();
        client.cache.gc();
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(MY_PERMISSIONS);
        // Set the logged-in status to false
        isLoggedInVar(false);
    }, [])

    return {
        logout
    }
}