import {
  Delete,
  Edit,
  EmojiEvents,
  FactCheck,
  ListAltRounded,
  PlusOne,
  RemoveRedEyeRounded,
  Save,
  Upload,
} from "@mui/icons-material";
import { Button, Chip, Paper, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTableUi from "../../components/data-table/DataTableUi";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { PRIMARY_COLOR } from "../../utils/constants";
import {
  CREATE_QUIZ,
  DELETE_QUIZ,
  GET_ALL_QUIZES,
  PUBLISH_QUIZ,
  UPDATE_QUIZ,
} from "./graphQL";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Space } from "antd";
import ActionBtn from "../../components/action-btn/ActionBtn";
import { promptBox } from "../../utils/helpers";
import { isLoading, systemInfoVar } from "../../store/cache";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

const Quiz = () => {
  const [modalTitle, setModalTitle] = useState("Add Quiz");

  const navigate = useNavigate();

  const systemInfo = useReactiveVar(systemInfoVar);

  const [editAfterPublished, setEAP] = useState(false);

  useEffect(() => {
    setEAP(systemInfo.canEditQuizAfterPublished);
  }, [systemInfo]);

  const {
    mutation: createQuiz,
    submiting,
    visible,
    setVisible,
    setSubmitting,
    setEditing,
    editing,
    setUuid,
    uuid,
    stopLoading,
  } = useGraphQLMutator(
    CREATE_QUIZ,
    "createQuiz",
    [GET_ALL_QUIZES],
    () => {},
    null,
    () => {
      setVisible(false);
      reset();
    }
  );

  const { mutation: delQuiz } = useGraphQLMutator(
    DELETE_QUIZ,
    "deleteQuiz",
    [GET_ALL_QUIZES],
    () => {
      isLoading(false);
    },
    `Successfully deleted`
  );

  const { mutation: publishNowQuiz } = useGraphQLMutator(
    PUBLISH_QUIZ,
    "publishQuiz",
    [GET_ALL_QUIZES],
    () => {
      isLoading(false);
    },
    `Successfully published`
  );

  const { mutation: updateQuiz } = useGraphQLMutator(
    UPDATE_QUIZ,
    "updateQuiz",
    [GET_ALL_QUIZES],
    () => {
      isLoading(false);
    },
    null,
    () => {
      setVisible(false);
      reset();
    }
  );

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const showModal = () => {
    setVisible(true);
    reset();
  };

  const manageQuestions = (uid) => {
    navigate(`/quizes/${uid}/questions`);
  };

  const previewQuiz = (uid) => {
    navigate(`/quizes/${uid}/preview`);
  };

  const publishQuiz = (uid) => {
    promptBox(() => {
      isLoading(true);
      publishNowQuiz({
        variables: {
          uid,
        },
      });
    }, `You are about to publish quiz, yes do it!`);
  };

  const viewQuizResults = (rec) => {
    navigate(`/quizes/${rec.uuid}/results`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Questions",
      key: "question",
      render: (_, rec) => {
        return <Chip label={rec.questions.length} size="small" />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, rec) => {
        if (rec.status === "PUBLISHED") {
          return <Chip label={rec.status} color="success" size="small" />;
        }
        return <Chip label={rec.status} size="small" />;
      },
    },
    {
      title: "Results Published",
      key: "results",
      render: (_, rec) => {
        if (rec.resultsOut) {
          return (
            <Chip
              label={`OUT`}
              variant="outlined"
              color="success"
              size="small"
            />
          );
        }
        return <Chip label={`NOT YET`} size="small" />;
      },
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Space>
            {record.questions.length !== 0 ? (
              <ActionBtn
                title="Preview Quiz"
                onClickIcon={() => previewQuiz(record.uuid)}
                icon={
                  <RemoveRedEyeRounded color="warning" fontSize="inherit" />
                }
              />
            ) : null}
            {record.status === "PUBLISHED" && (
              <>
                <ActionBtn
                  title="View Quiz Results"
                  onClickIcon={() => viewQuizResults(record)}
                  icon={<FactCheck color="success" fontSize="inherit" />}
                />
              </>
            )}
            {record.status === "PUBLISHED" && editAfterPublished && (
              <>
                <ActionBtn
                  title="Edit Quiz"
                  onClickIcon={() => editQuiz(record)}
                  icon={<Edit color="primary" fontSize="inherit" />}
                />
                <ActionBtn
                  title="Manage Questions"
                  onClickIcon={() => manageQuestions(record.uuid)}
                  icon={<ListAltRounded color="primary" fontSize="inherit" />}
                />
                <ActionBtn
                  title="Delete Quiz"
                  onClickIcon={() => deleteQuiz(record.uuid)}
                  icon={<Delete color="error" fontSize="inherit" />}
                />
              </>
            )}
            {record.status === "DRAFTED" && (
              <>
                <ActionBtn
                  title="Edit Quiz"
                  onClickIcon={() => editQuiz(record)}
                  icon={<Edit color="primary" fontSize="inherit" />}
                />
                <ActionBtn
                  title="Manage Questions"
                  onClickIcon={() => manageQuestions(record.uuid)}
                  icon={<ListAltRounded color="primary" fontSize="inherit" />}
                />
                {record.questions.length !== 0 ? (
                  <ActionBtn
                    title="Publish Quiz"
                    onClickIcon={() => publishQuiz(record.uuid)}
                    icon={<Upload color="success" fontSize="inherit" />}
                  />
                ) : null}
                <ActionBtn
                  title="Delete Quiz"
                  onClickIcon={() => deleteQuiz(record.uuid)}
                  icon={<Delete color="error" fontSize="inherit" />}
                />
              </>
            )}
            {record.resultsOut && (
              <Tooltip title="This shows that results are out!">
                <EmojiEvents
                  color="success"
                  style={{ fontSize: 28 }}
                  fontSize="inherit"
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  const editQuiz = (rec) => {
    setVisible(true);
    setModalTitle("Edit Quiz");
    setValue("name", rec.name);
    setValue("description", rec.description);
    setUuid(rec.uuid);
    setEditing(true);
  };

  const deleteQuiz = (uid) => {
    promptBox(() => {
      isLoading(true);
      delQuiz({
        variables: {
          uid,
        },
      });
    });
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    if (editing) {
      updateQuiz({
        variables: {
          uid: uuid,
          input: {
            ...data,
          },
        },
      });
    } else {
      createQuiz({
        variables: {
          input: {
            ...data,
          },
        },
      });
    }
  };

  return (
    <>
      <TitleBoxUi title="Manage Quizes" icon={<FactCheck />}>
        <Button
          onClick={showModal}
          variant="contained"
          sx={{ background: PRIMARY_COLOR }}
          startIcon={<Save />}
        >
          Add Quiz
        </Button>
      </TitleBoxUi>

      <Paper
        sx={{
          mt: 2,
          padding: 2,
        }}
      >
        <ModalContainerUi
          title={modalTitle}
          visible={visible}
          onCancel={() => {
            setVisible(false); /*reset();*/
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              name="name"
              {...register("name")}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />

            <TextField
              multiline
              rows={3}
              margin="normal"
              fullWidth
              label="Description"
              name="description"
              {...register("description")}
            />

            <ModalFooter
              loading={submiting}
              onCancel={() => {
                setVisible(false);
                reset();
              }}
            />
          </form>
        </ModalContainerUi>
        <DataTableUi
          columns={columns}
          query={GET_ALL_QUIZES}
          queryName="getQuizes"
        />
      </Paper>
    </>
  );
};

export default Quiz;
