import { Add, Delete } from "@mui/icons-material";
import { Checkbox, IconButton, Paper, TextField } from "@mui/material";
import React, { forwardRef, useRef, useState } from "react";

const MultipleAnswer = forwardRef(
  (
    {
      handleAnswerRemove,
      id,
      handlePossibleAnswer,
      handleCheckedPossibleAnswer,
    },
    ref
  ) => {
    return (
      <>
        <Paper sx={{ padding: 1, mb: 1 }} elevation={0}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: 5,
            }}
          >
            <Checkbox
              ref={ref}
              onChange={(e) =>
                handleCheckedPossibleAnswer(e.target.checked, id)
              }
            />
            <TextField
              onChange={(e) => {
                handlePossibleAnswer(e.target.value, id);
              }}
              label="Possible Answer"
              fullWidth
              multiline
              rows={2}
            />
            <IconButton onClick={() => handleAnswerRemove(id)}>
              <Delete />
            </IconButton>
          </div>
        </Paper>
      </>
    );
  }
);

export default MultipleAnswer;
