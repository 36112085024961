import { gql } from "@apollo/client";

export const GET_ALL_QUIZES = gql`
  query getQuizes {
    getQuizes {
      uuid
      name
      description
      status
      resultsOut
      questions {
        uuid
      }
      createdAt
    }
  }
`;

export const CREATE_QUIZ = gql`
  mutation createQuiz($input: QuizInput!) {
    createQuiz(input: $input) {
      code
      errorDescription
      status
      data {
        uuid
      }
    }
  }
`;

export const DELETE_QUIZ = gql`
  mutation deleteQuiz($uid: String!) {
    deleteQuiz(uid: $uid) {
      code
      errorDescription
      status
      data {
        uuid
      }
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation updateQuiz($uid: String!, $input: QuizInput!) {
    updateQuiz(uid: $uid, input: $input) {
      code
      errorDescription
      status
      data {
        uuid
      }
    }
  }
`;

export const GET_QUIZ = gql`
  query getQuiz($uid: String!) {
    getQuiz(uid: $uid) {
      uuid
      name
      description
      isPublished
      createdAt
      status
      resultsOut
      questions {
        uuid
        question
        imageData
        weight
        qnType
        answers {
          uuid
          weight
          possibleAnswer
          correct
          createdAt
        }
      }
    }
  }
`;

export const START_QUIZ = gql`
  query getQuiz($uid: String!) {
    getQuiz(uid: $uid) {
      uuid
      name
      description
      isPublished
      createdAt
      status
      questions {
        uuid
        question
        imageData
        weight
        qnType
        answers {
          uuid
          weight
          possibleAnswer
          createdAt
        }
      }
    }
  }
`;

export const GET_MY_QUIZ = gql`
  query getMyQuiz($uid: String!) {
    getMyQuiz(uid: $uid) {
      uuid
      name
      description
      isPublished
      createdAt
      status
      correctCount
      totalCount
      questions {
        uuid
        question
        imageData
        weight
        qnType
        myAnswers {
          uuid
          correct
          weight
          possibleAnswer
          myAttempt
          createdAt
        }
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion($input: QuestionInput!) {
    createQuestion(input: $input) {
      code
      data {
        uuid
        question
      }
      errorDescription
      status
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($uuid: String!) {
    deleteQuestion(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const UPDATE_QUESTION_BODY = gql`
  mutation updateQuestionBody($uuid: String!, $question: String!) {
    updateQuestionBody(uuid: $uuid, question: $question) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const DELETE_QUESTION_ANSWER = gql`
  mutation deleteQuestionAnswer($auuid: String!, $quuid: String!) {
    deleteQuestionAnswer(auuid: $auuid, quuid: $quuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const UPDATE_QUESTION_ANSWER = gql`
  mutation updateQuestionAnswer($auuid: String!, $answer: String!) {
    updateQuestionAnswer(auuid: $auuid, answer: $answer) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const ADD_MORE_QUESTION = gql`
  mutation addMoreQuestionAnswer($quuid: String!, $answer: String!) {
    addMoreQuestionAnswer(quuid: $quuid, answer: $answer) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const CHANGE_ANSWER_CORRECT = gql`
  mutation changeQuestionAnswerCorrect(
    $quuid: String!
    $auuid: String!
    $checked: Boolean!
    $qnatype: Float!
  ) {
    changeQuestionAnswerCorrect(
      quuid: $quuid
      auuid: $auuid
      checked: $checked
      qnatype: $qnatype
    ) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const CHANGE_UPLOAD = gql`
  mutation changeQuestionUpload($uuid: String!, $ext: String!, $data: String!) {
    changeQuestionUpload(uuid: $uuid, ext: $ext, data: $data) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteQuestionUpload($uuid: String!) {
    deleteQuestionUpload(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const RE_ORDER_QUESTIONS = gql`
  mutation reOrderQuestions($input: ReorderQuestionsInput!) {
    reOrderQuestions(input: $input) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const RE_ORDER_ANSWERS = gql`
  mutation reOrderAnswers($input: ReorderAnswersInput!) {
    reOrderAnswers(input: $input) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const CHANGE_SINGLE_ANSWER_CORRECT = gql`
  mutation changeSingleQuestionAnswerCorrect(
    $quuid: String!
    $auuid: String!
    $qnatype: Float!
  ) {
    changeSingleQuestionAnswerCorrect(
      quuid: $quuid
      auuid: $auuid
      qnatype: $qnatype
    ) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const PUBLISH_QUIZ = gql`
  mutation publishQuiz($uid: String!) {
    publishQuiz(uid: $uid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const GET_MY_UN_ATTEMPTED_QUIZES = gql`
  query {
    getMyUnAttemptQuizs {
      uuid
      name
      description
      questions {
        uuid
      }
      publishedAt
      createdAt
    }
  }
`;

export const GET_MY_UN_ATTEMPTED_QUIZES_BY_YEAR = gql`
  query getMyUnAttemptQuizsByYear($year: String!) {
    getMyUnAttemptQuizsByYear(year: $year) {
      uuid
      name
      description
      questions {
        uuid
      }
      publishedAt
      createdAt
    }
  }
`;

export const GET_MY_ATTEMPTED_QUIZES = gql`
  query {
    getMyAttemptQuizs {
      uuid
      name
      description
      checkedResultsOut
      questions {
        uuid
      }
      submittedDate
      publishedAt
      createdAt
    }
  }
`;

export const SUBMIT_QUIZ_ATTEMPTS = gql`
  mutation submitUserQuizAttempts($input: UserQuizAttemptsInput!) {
    submitUserQuizAttempts(input: $input) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const GET_QUIZ_RESULTS = gql`
  query getQuizResults($uid: String!) {
    getQuizResults(uid: $uid) {
      uuid
      user {
        uuid
        email
        fullName
      }
      quiz_total_questions
      correctCount
      submittedDate
      totalCount
    }
    getQuiz(uid: $uid) {
      uuid
      name
      description
      isPublished
      createdAt
      status
      resultsOut
      questions {
        uuid
        question
        imageData
        weight
        qnType
        answers {
          uuid
          weight
          possibleAnswer
          createdAt
        }
      }
    }
  }
`;

export const GET_ATTEMPTED_QUIZ = gql`
  query getAttemptedQuiz($attemptedUid: String!) {
    getAttemptedQuiz(attemptedUid: $attemptedUid) {
      uuid
      user {
        fullName
      }
      quiz {
        uuid
        name
        questions {
          uuid
          question
          qnType
          weight
          deleted
          imageData
          answers {
            uuid
            possibleAnswer
            myAttempt
            correct
          }
        }
      }
    }
  }
`;

export const PUBLISH_QUIZ_RESULTS = gql`
  mutation publishQuizResults($uid: String!) {
    publishQuizResults(uid: $uid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const CHECK_MY_QUIZ_RESULTS = gql`
  mutation checkMyQuizResults($quizUid: String!) {
    checkMyQuizResults(quizUid: $quizUid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const PUBLISH_QUIZ_FOR_USER = gql`
  mutation publishQuizForUser($useruuid: String!) {
    publishQuizForUser(useruuid: $useruuid)
  }
`;

export const PUBLISH_QUIZ_RESULTS_FOR_USER = gql`
  mutation publishQuizResultsForUser($useruuid: String!, $quizuuid: String!) {
    publishQuizResultsForUser(useruuid: $useruuid, quizuuid: $quizuuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;
