import { useQuery } from "@apollo/client";
import { ArrowBack, List, RadioButtonChecked } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Paper,
  Radio,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import Uploader from "../../components/uploader/Uploader";
import { PRIMARY_COLOR } from "../../utils/constants";
import { START_QUIZ, SUBMIT_QUIZ_ATTEMPTS } from "../quiz/graphQL";

import parser from "html-react-parser";
import { infoBox, promptBox } from "../../utils/helpers";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading } from "../../store/cache";
import QuizStartAnswerList from "./QuizStartAnswerList";
import { UN_ATTEMPTED_QUIZS } from "../dashboard/graphQL";

const QuizStart = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [myAttempts, setMyAttempts] = useState([]);
  const [userAttempts, setUserAttempts] = useState([]);

  const { data, loading } = useQuery(START_QUIZ, {
    variables: {
      uid,
    },
    fetchPolicy: "network-only",
  });

  const { mutation: submitUserQuizAttempts } = useGraphQLMutator(
    SUBMIT_QUIZ_ATTEMPTS,
    "submitUserQuizAttempts",
    [],
    () => {
      isLoading(false);
    },
    `Successfully submitted`,
    () => {
      window.location = "/dashboard";
    }
  );

  const handleQuizSubmit = () => {
    if (userAttempts.length === 0) {
      promptBox(() => {}, `You will fail the quiz, nothing has been attempted`);
    } else {
      promptBox(() => {
        const attempts = userAttempts.map((a) => ({
          qnUid: a.quid,
          answerUid: a.auid,
        }));
        isLoading(true);
        submitUserQuizAttempts({
          variables: {
            input: {
              quizUid: uid,
              attempts: attempts,
            },
          },
        });
      }, `You are about to submit your quiz, yes! do it,`);
    }
  };

  const notify = (data) => {
    setUserAttempts(data);
  };

  const handleCheckedPossibleAnswer = (checked, auid, quuid) => {
    if (checked) {
      if (!myAttempts.some((a) => a.auid === auid)) {
        const ua = {
          auid: auid,
          quid: quuid,
        };
        const newAttempts = [ua, ...myAttempts];
        notify(newAttempts);
        setMyAttempts(newAttempts);
      }
    } else {
      if (myAttempts.some((a) => a.auid === auid)) {
        const filterAttempts = myAttempts.filter((m) => m.auid !== auid);
        const newAttempts = [...filterAttempts];
        notify(newAttempts);
        setMyAttempts(newAttempts);
      }
    }
  };

  const handlePossibleAnswer = (auid, quid) => {
    if (!myAttempts.some((a) => a.quid === quid)) {
      const ua = {
        auid: auid,
        quid: quid,
      };
      const newAttempts = [ua, ...myAttempts];
      notify(newAttempts);
      setMyAttempts(newAttempts);
    } else {
      const filterAttempts = myAttempts.map((m) => {
        if (m.quid === quid) {
          m.auid = auid;
          return m;
        } else {
          return m;
        }
      });
      const newAttempts = [...filterAttempts];
      notify(newAttempts);
      setMyAttempts(newAttempts);
    }
  };

  return (
    <>
      {loading && (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      )}
      {data && (
        <TitleBoxUi
          title={`Quiz Title: ${data.getQuiz.name} | Total Questions: ${data.getQuiz.questions.length}`}
          icon={<List />}
        >
          <div>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                mr: 1,
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                  background: "white",
                },
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        </TitleBoxUi>
      )}

      {data && (
        <>
          {data.getQuiz.questions.map((q, i) => (
            <div className="questions" key={i}>
              <Paper sx={{ padding: 1, mb: 3, mt: 3 }} elevation={4}>
                <Paper sx={{ padding: 1, mb: 1 }} elevation={4}>
                  <div
                    className="d-flex justify-content-between"
                    style={{ fontSize: "1rem" }}
                  >
                    <span className="d-flex">
                      <p style={{ paddingRight: 2 }}>{`${i + 1}`})</p>
                      <div>{parser(q.question)}</div>
                    </span>
                  </div>
                </Paper>
                <div className="row">
                  <div className="col-md-12">
                    {q.imageData && (
                      <Paper elevation={0} sx={{ mb: 2, ml: 5, width: 600 }}>
                        <Uploader
                          getBaseData={(data) => null}
                          getFileExt={(ext) => null}
                          title={null}
                          qUid={q.uuid}
                          getQnUploadMeta={(uuid, file, ext) => null}
                          data={q.imageData.split(",")[1]}
                        />
                      </Paper>
                    )}

                    <QuizStartAnswerList
                      possibleAnswer={(auuid, quuid) =>
                        handlePossibleAnswer(auuid, quuid)
                      }
                      checkedPossibleAnswer={(checked, auuid, quuid) =>
                        handleCheckedPossibleAnswer(checked, auuid, quuid)
                      }
                      answers={q.answers}
                      q={q}
                    />
                  </div>
                </div>
              </Paper>
            </div>
          ))}
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Button
            onClick={handleQuizSubmit}
            variant="filled"
            sx={{
              background: PRIMARY_COLOR,
              color: "white",
              "&:hover": {
                color: PRIMARY_COLOR,
              },
            }}
          >
            Submit Quiz Now
          </Button>
        </>
      )}
    </>
  );
};

export default QuizStart;
