import { useReactiveVar } from "@apollo/client";
import { Edit, Save, SettingsApplications } from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import Uploader from "../../../components/uploader/Uploader";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading, systemInfoVar } from "../../../store/cache";
import { PRIMARY_COLOR } from "../../../utils/constants";
import { GET_SYSTEM_INFO } from "../../login/graphQL";
import {
  SAVE_SYSTEM_INFO,
  SAVE_SYSTEM_LOGO,
  SAVE_SYSTEM_NOTIFY_EMAIL,
  SAVE_SYSTEM_TITLE,
} from "../graphQL";

const GeneralSettings = () => {
  const systemInfo = useReactiveVar(systemInfoVar);

  const [updating, setUpdating] = useState(false);

  const { mutation: saveSystemInfo } = useGraphQLMutator(
    SAVE_SYSTEM_INFO,
    "saveSystemTitle",
    [GET_SYSTEM_INFO],
    () => {
      isLoading(false);
      setUpdating(false);
    },
    null,
    () => {
      isLoading(false);
      setUpdating(false);
    }
  );

  const { mutation: saveSystemLogo } = useGraphQLMutator(
    SAVE_SYSTEM_LOGO,
    "saveSystemLogo",
    [GET_SYSTEM_INFO],
    () => {
      isLoading(false);
      setUpdating(false);
    },
    null,
    () => {
      isLoading(false);
      setUpdating(false);
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (systemInfo) {
      setValue("title", systemInfo.systemTitle);
      setValue("email", systemInfo.notifyEmail);
    }
  }, [systemInfo]);

  const onSubmit = (data) => {
    setUpdating(true);
    saveSystemInfo({
      variables: {
        email: data.email,
        title: data.title,
      },
    });
  };

  const changeSystemLogo = (data) => {
    setUpdating(true);
    saveSystemLogo({
      variables: {
        logo: data,
      },
    });
  };

  return (
    <>
      <TitleBoxUi
        title={`System General Information Settings`}
        icon={<Edit />}
      ></TitleBoxUi>
      {updating && <LinearProgress />}
      <Paper sx={{ mt: 2, padding: 2 }}>
        <div className="row">
          <div className="col-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="col-12">
                  <Typography>System Title</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="System Title"
                    name="title"
                    {...register("title", {
                      required: "System Title is required.",
                    })}
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                  />
                </div>
                <div className="col-12" style={{ marginTop: 5 }}>
                  <Typography>User Signup Notification Email</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="Notification Email"
                    name="title"
                    {...register("email", {
                      required: "Email is required.",
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    startIcon={<Save />}
                    variant="filled"
                    sx={{
                      background: PRIMARY_COLOR,
                      color: "white",
                      "&:hover": {
                        color: PRIMARY_COLOR,
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-6">
            {systemInfo && (
              <Uploader
                getBaseData={(data) => changeSystemLogo(data)}
                getFileExt={(ext) => null}
                title="Change System Logo"
                data={systemInfo.logo}
                ext={systemInfo.fileExt || `png`}
              />
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default GeneralSettings;
