import { Save } from "@mui/icons-material";
import { Button, CircularProgress, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Space } from "antd";
import React from "react";
import { PRIMARY_COLOR } from "../../utils/constants";

const ModalFooter = ({
  onCancel,
  loading = false,
  sBtn = true,
  handleOnClick,
  disabled = false,
  text = "Save",
}) => {
  return (
    <>
      <Divider sx={{ mt: 3 }} />
      <div className="d-flex flex-row-reverse">
        <Space size="middle">
          <Button
            onClick={onCancel}
            color="error"
            size="medium"
            variant="contained"
          >
            Cancel
          </Button>

          <Box sx={{ m: 1, position: "relative" }}>
            {sBtn ? (
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                startIcon={text === "Save" ? <Save /> : null}
                size="medium"
                sx={{ backgroundColor: "blue" }}
              >
                {text}
              </Button>
            ) : (
              <Button
                onClick={handleOnClick}
                variant="contained"
                disabled={loading || disabled}
                startIcon={<Save />}
                size="medium"
                sx={{ backgroundColor: "blue" }}
              >
                {text}
              </Button>
            )}
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Space>
      </div>
    </>
  );
};

export default ModalFooter;
