import { gql } from "@apollo/client";

export const GET_USER_PERMISSIONS = gql`
  query {
    getUserPermissions
  }
`;

export const GET_SYSTEM_INFO = gql`
  query getSystemInfoSettings {
    getSystemInfoSettings {
      systemTitle
      logoPrefix
      logo
      fileExt
      notifyEmail
      companyName
      companyWebsite
      companyEmail
      companyTelephone
      companyAddress
      quizReminderDays
      isPushNotificationEnabled
      canEditQuizAfterPublished
      isAnnualServicePaid
    }
  }
`;
