import {
  Alert,
  Button,
  Chip,
  List,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import {
  Add,
  ApartmentRounded,
  Delete,
  Edit,
  RemoveRedEye,
} from "@mui/icons-material";
import { PRIMARY_COLOR } from "../../utils/constants";
import DataTableUi from "../../components/data-table/DataTableUi";
import { Space } from "antd";
import ActionBtn from "../../components/action-btn/ActionBtn";
import {
  CREATE_SITE,
  DELETE_SITE,
  GET_ALL_SITES,
  UPDATE_SITE,
} from "./graphQL";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { useForm } from "react-hook-form";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import { promptBox } from "../../utils/helpers";
import { isLoading } from "../../store/cache";

import parser from "html-react-parser";
import { useNavigate } from "react-router-dom";

const Sites = () => {
  const [modalTitle, setModalTitle] = useState("Add Site");

  const navigate = useNavigate();

  const {
    mutation: createSite,
    submiting,
    visible,
    setVisible,
    setSubmitting,
    setEditing,
    editing,
    setUuid,
    uuid,
  } = useGraphQLMutator(
    CREATE_SITE,
    "createSite",
    [GET_ALL_SITES],
    () => {},
    null,
    () => {
      reset();
    }
  );

  const { mutation: deleteSiteMutation } = useGraphQLMutator(
    DELETE_SITE,
    "deleteSite",
    [GET_ALL_SITES],
    () => {
      isLoading(false);
    },
    `Successfully deleted!`
  );

  const { mutation: updateSite } = useGraphQLMutator(
    UPDATE_SITE,
    "updateSite",
    [GET_ALL_SITES],
    () => {
      setVisible(false);
      isLoading(false);
      setSubmitting(false);
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const showModal = () => {
    setVisible(true);
    reset();
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    if (editing) {
      updateSite({
        variables: {
          uuid,
          input: {
            ...data,
          },
        },
      });
    } else {
      createSite({
        variables: {
          input: {
            ...data,
          },
        },
      });
    }
  };

  const deleteSite = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteSiteMutation({
        variables: {
          uuid,
        },
      });
    });
  };

  const handleEdit = (site) => {
    setVisible(true);
    setModalTitle("Edit Site");
    setEditing(true);
    setValue("name", site.name);
    setValue("description", site.description);
    setUuid(site.uuid);
  };

  const handleView = (uuid) => {
    navigate(`/sites/${uuid}/view`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => (
        <p>
          {typeof record.description === "string"
            ? parser(record.description)
            : null}
        </p>
      ),
    },
    {
      title: "Departments",
      key: "departments",
      render: (_, record) => (
        <Chip variant="filled" color="info" label={record.departments.length} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <ActionBtn
            title="Edit Site"
            onClickIcon={() => handleEdit(record)}
            icon={<Edit color="info" fontSize="inherit" />}
          />
          <ActionBtn
            onClickIcon={() => handleView(record.uuid)}
            icon={<RemoveRedEye color="success" fontSize="inherit" />}
            title="View Site"
          />

          <ActionBtn
            title="Delete Site"
            onClickIcon={() => deleteSite(record.uuid)}
            icon={<Delete color="error" fontSize="inherit" />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <TitleBoxUi title="Manage Sites" icon={<ApartmentRounded />}>
        <Button
          onClick={showModal}
          variant="contained"
          sx={{ background: PRIMARY_COLOR }}
          startIcon={<Add />}
        >
          Add Sites
        </Button>
      </TitleBoxUi>

      <Paper
        sx={{
          mt: 2,
          padding: 2,
        }}
      >
        <ModalContainerUi
          top={null}
          title={modalTitle}
          visible={visible}
          onCancel={() => {
            setVisible(false); /*reset();*/
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Typography>Name</Typography>
              <TextField
                margin="normal"
                fullWidth
                label="Name"
                name="name"
                {...register("name", { required: "Name is required." })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </div>

            <div>
              <Typography sx={{ mb: 1 }}>Description</Typography>
              <TextField
                multiline
                rows={3}
                margin="normal"
                fullWidth
                label="Description"
                name="description"
                {...register("description")}
              />
            </div>

            <ModalFooter
              loading={submiting}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </form>
        </ModalContainerUi>
        <DataTableUi
          columns={columns}
          query={GET_ALL_SITES}
          queryName="getSites"
        />
      </Paper>
    </>
  );
};

export default Sites;
