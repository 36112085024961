import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { PRIMARY_COLOR } from "../../utils/constants";
import logo from "../../assets/logo.png";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_PUBLIC_SITES } from "../sites/graphQL";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { REGISTER_USER, REGISTER_USER_PUBLIC } from "../users/graphQL";
import { onJoinClickedVar, systemInfoVar } from "../../store/cache";
import { showToastTop } from "../../utils/helpers";

const JoinNow = () => {
  const [registering, setRegistering] = useState(false);

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [depts, setDepts] = useState([]);
  const [selectedDept, setSelectedDept] = useState();

  const { loading, data } = useQuery(GET_PUBLIC_SITES);

  const systemInfo = useReactiveVar(systemInfoVar);

  useEffect(() => {
    if (data) {
      setSites(data.getPublicSites);
    }
  }, [data]);

  const { mutation: createUser } = useGraphQLMutator(
    REGISTER_USER_PUBLIC,
    "createUser",
    [],
    () => {
      setRegistering(false);
    },
    `Successfully created!, but your account is locked!`,
    () => {
      onJoinClickedVar(false);
    },
    true
  );

  const formSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    site: Yup.string().required("Site is required"),
    department: Yup.string().required("Department is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email provided"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data) => {
    const { site, department, ...others } = data;
    const siteUid = getSiteUid(site);
    const departmentUid = getDeptUid(department);

    const newData = {
      siteUid,
      departmentUid,
      ...others,
    };

    setRegistering(true);

    createUser({
      variables: {
        input: {
          ...newData,
        },
      },
    });
  };

  const getSiteUid = (site) => {
    const ss = sites.filter((s) => s.name === site)[0];
    return ss.uuid;
  };

  const getDeptUid = (dept) => {
    console.log(depts);
    const dd = depts.filter((d) => d.name === dept)[0];
    return dd.uuid;
  };

  return (
    <>
      {registering && <LinearProgress />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {systemInfo.logo && (
            <img
              src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
              alt="Logo"
              style={{
                marginTop: 25,
                width: 100,
              }}
            />
          )}
          <p>Register New Account</p>
        </div>
        <TextField
          margin="normal"
          fullWidth
          id="fullName"
          label="Full Name"
          name="fullName"
          {...register("fullName")}
          error={Boolean(errors.fullName)}
          helperText={errors.fullName?.message}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Email Address"
          name="email"
          {...register("email")}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <Autocomplete
          sx={{ mt: 1, mb: 1 }}
          options={sites.map((site) => site.name)}
          value={selectedSite}
          onChange={(event, newValue) => {
            setSelectedSite(newValue);
            const site = sites.filter((s) => s.name == newValue)[0];
            setSelectedDept("");
            setDepts(site.departments);
          }}
          renderInput={(params) => (
            <TextField
              {...register("site")}
              error={Boolean(errors.site)}
              helperText={errors.site?.message}
              {...params}
              label="Select Site"
              variant="outlined"
            />
          )}
        />

        <Autocomplete
          sx={{ mt: 2, mb: 1 }}
          options={depts.map((dept) => dept.name)}
          value={selectedDept}
          onChange={(event, newValue) => {
            setSelectedDept(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("department")}
              error={Boolean(errors.department)}
              helperText={errors.department?.message}
              label="Select Department"
              variant="outlined"
            />
          )}
        />

        <TextField
          margin="normal"
          fullWidth
          label="Password"
          name="password"
          type="password"
          {...register("password")}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
        <TextField
          margin="normal"
          fullWidth
          type="password"
          label="Confirm Password"
          name="confirmPassword"
          {...register("confirmPassword")}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginY: 1,
            mt: 2,
          }}
        >
          <Button
            type="submit"
            size="large"
            sx={{ width: "100%", backgroundColor: PRIMARY_COLOR }}
            variant="contained"
          >
            Create Account
          </Button>
        </Box>
      </form>
    </>
  );
};

export default JoinNow;
