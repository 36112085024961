import { useQuery } from "@apollo/client";
import { Modal } from "@mantine/core";

import parser from "html-react-parser";

import "./QuizQuestions.css";

import { Add, ArrowBack, Delete, Edit, List } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditBox from "../../components/edit-box/EditBox";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading } from "../../store/cache";
import { PRIMARY_COLOR } from "../../utils/constants";
import {
  ADD_MORE_QUESTION,
  CHANGE_ANSWER_CORRECT,
  CHANGE_UPLOAD,
  CREATE_QUESTION,
  DELETE_ATTACHMENT,
  DELETE_QUESTION,
  DELETE_QUESTION_ANSWER,
  GET_QUIZ,
  RE_ORDER_ANSWERS,
  RE_ORDER_QUESTIONS,
  UPDATE_QUESTION_ANSWER,
  UPDATE_QUESTION_BODY,
} from "../quiz/graphQL";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import Uploader from "../../components/uploader/Uploader";
import MultipleAnswerList from "../../components/multiple-answer/MultipleAnswerList";
import { infoBox, promptBox } from "../../utils/helpers";
import SingleAnswerList from "../../components/single-answer/SingleAnswerList";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import { Reorder } from "framer-motion";
import AnswersEditor from "../../components/answers-editor/AnswersEditor";

const QuizQuestions = () => {
  const { uid } = useParams();
  const navigate = useNavigate();

  const [qnSQnUid, setSQnUid] = useState(null);

  const [question, setQuestion] = useState();
  const [selectedDescription, setSelectedDescription] = useState();
  const [error, setError] = useState(null);
  const [errorEdit, setErrorEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState();
  const [ext, setExt] = useState();
  const [editEquestion, setEditQuestion] = useState(false);
  const [qnUid, setQnUid] = useState();
  const [auid, setAUid] = useState();
  const [qnEditLoding, setQnEditLoading] = useState(false);
  const [aEditLoding, setAEditLoding] = useState(false);

  const [editAnswer, setEditAnswer] = useState(false);
  const [addAnswer, setAddAnswer] = useState(false);
  const [answer, setAnswer] = useState();

  const [errorAnswerAdd, setErrorAnswerAdd] = useState(null);

  const [selectedAnswer, setSelectedAnswer] = useState();
  const [errorAnswerEdit, setErrorAnswerEdit] = useState();

  const [qnType, setQnType] = useState(0);

  const [possibleAnswers, setPossibleAnswers] = useState([]);

  const [answerSub, setAnswerSub] = useState(false);

  const [subPic, setSubPic] = useState(false);

  const [uploadTach, setUploadTach] = useState(false);
  const [qnUUID, setQnUUID] = useState();
  const [dataUpload, setDataUpload] = useState(null);

  const location = useLocation();

  const [mQuestions, setMQuestions] = useState([]);

  const { data, loading: loadng } = useQuery(GET_QUIZ, {
    variables: {
      uid,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setMQuestions(data.getQuiz.questions);
    }
  }, [data]);

  const { mutation: deleteQuestion } = useGraphQLMutator(
    DELETE_QUESTION,
    "deleteQuestion",
    [GET_QUIZ],
    () => {},
    "Successfully deleted",
    () => {
      isLoading(false);
    }
  );

  const { mutation: deleteQuestionAnswer } = useGraphQLMutator(
    DELETE_QUESTION_ANSWER,
    "deleteQuestionAnswer",
    [GET_QUIZ],
    () => {},
    "Successfully deleted",
    () => {
      isLoading(false);
    }
  );

  const { mutation: updateQuestionBody } = useGraphQLMutator(
    UPDATE_QUESTION_BODY,
    "updateQuestionBody",
    [GET_QUIZ],
    () => {},
    null,
    () => {
      isLoading(false);
      setEditQuestion(false);
    }
  );

  const { mutation: addMoreQuestionAnswer } = useGraphQLMutator(
    ADD_MORE_QUESTION,
    "addMoreQuestionAnswer",
    [GET_QUIZ],
    () => {},
    null,
    () => {
      isLoading(false);
      setAddAnswer(false);
      setAnswerSub(false);
      setAnswer();
      setQnUid();
    }
  );

  const {
    mutation: createQuestion,
    visible,
    setVisible,
  } = useGraphQLMutator(
    CREATE_QUESTION,
    "createQuestion",
    [GET_QUIZ],
    () => {
      isLoading(false);
      setLoading(false);
    },
    null,
    () => {
      setVisible(false);
      setExt();
      setImageData();
      setQnType(0);
      setPossibleAnswers([]);
      setQuestion("<p><br></p>");
    }
  );

  const { mutation: updateQuestionAnswer } = useGraphQLMutator(
    UPDATE_QUESTION_ANSWER,
    "updateQuestionAnswer",
    [GET_QUIZ],
    () => {
      isLoading(false);
      setAEditLoding(false);
    },
    null,
    () => {
      setEditAnswer(false);
      setSelectedAnswer();
    }
  );

  const { mutation: changeQuestionAnswerCorrect } = useGraphQLMutator(
    CHANGE_ANSWER_CORRECT,
    "changeQuestionAnswerCorrect",
    [GET_QUIZ],
    () => {
      isLoading(false);
    },
    null,
    () => {},
    false,
    false
  );

  const { mutation: reOrderQuestions } = useGraphQLMutator(
    RE_ORDER_QUESTIONS,
    "reOrderQuestions",
    [GET_QUIZ],
    () => {
      isLoading(false);
    },
    null,
    () => {}
  );

  const { mutation: changeQuestionUpload } = useGraphQLMutator(
    CHANGE_UPLOAD,
    "changeQuestionUpload",
    [GET_QUIZ],
    () => {
      isLoading(false);
      setSubPic(false);
    },
    null,
    () => {
      window.location = "";
    }
  );

  const { mutation: deleteQuestionUpload } = useGraphQLMutator(
    DELETE_ATTACHMENT,
    "deleteQuestionUpload",
    [GET_QUIZ],
    () => {
      isLoading(false);
      setSubPic(false);
    },
    null,
    () => {}
  );

  useEffect(() => {
    isLoading(loading);
  }, [loading]);

  const showModal = () => {
    setVisible(true);
  };

  const handleQnType = (e) => {
    setQnType(e.target.value);
  };

  const uploadAttachment = (uid) => {
    setUploadTach(true);
    setQnUUID(uid);
    setDataUpload(null);
  };

  const handleQuestionRemove = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteQuestion({
        variables: {
          uuid,
        },
      });
    });
  };

  const handleQuestionEdit = (qn) => {
    setEditQuestion(true);
    setSelectedDescription(qn.question);
    setQnUid(qn.uuid);
    setQnEditLoading(false);
  };

  const handleUpdateQuestionBody = () => {
    if (selectedDescription === "<p><br></p>") {
      setErrorEdit("Please provide question");
      return;
    }

    setQnEditLoading(true);
    isLoading(true);

    updateQuestionBody({
      variables: {
        uuid: qnUid,
        question: selectedDescription,
      },
    });
  };

  const handleUpdateAnswer = () => {
    if (selectedAnswer === "<p><br></p>") {
      setErrorAnswerEdit("Please provide answer");
      return;
    }

    setAEditLoding(true);
    isLoading(true);

    updateQuestionAnswer({
      variables: {
        auuid: auid,
        answer: selectedAnswer,
      },
    });
  };

  const saveQuestion = () => {
    if (question === "<p><br></p>") {
      setError("Please provide question");
      return;
    }

    if (possibleAnswers.length === 0) {
      infoBox(`Please fill all possible answers correctly`);
      return;
    }

    const found = possibleAnswers.some((p) => p.correct === true);

    if (!found) {
      infoBox(`Please fill all possible answers correctly`);
      return;
    }

    possibleAnswers.forEach((p) => {
      if (p.possibleAnswer === "") {
        infoBox(`Please fill all possible answers correctly`);
        return;
      }
    });

    setLoading(true);

    const newData = {
      question,
      possibleAnswers,
      qnType,
      imageData: imageData,
      ext: ext,
      quizUid: uid,
    };

    createQuestion({
      variables: {
        input: {
          ...newData,
        },
      },
    });
  };

  const handleAnswerEdit = (a) => {
    setEditAnswer(true);
    setSelectedAnswer(a.possibleAnswer);
    setAUid(a.uuid);
  };

  const handleAnswerRemove = (auuid, quuid) => {
    promptBox(() => {
      deleteQuestionAnswer({
        variables: {
          auuid,
          quuid,
        },
      });
    });
  };

  const handleUploadMeta = (uid, file, ext) => {
    setSubPic(true);
    changeQuestionUpload({
      variables: {
        uuid: uid,
        ext,
        data: file,
      },
    });
  };

  const handleAddPossibleAnswer = () => {
    if (answer === "") {
      infoBox("Please fill the blank");
      return;
    }

    setAnswerSub(true);
    addMoreQuestionAnswer({
      variables: {
        quuid: qnUid,
        answer,
      },
    });
  };

  const handleAnswerChecked = (e, auuid, quuid, changeQnType = 0) => {
    const checked = e.target.checked;

    changeQuestionAnswerCorrect({
      variables: {
        quuid,
        auuid,
        checked,
        qnatype: changeQnType,
      },
    });
  };

  const removeAttachment = (uuid) => {
    promptBox(() => {
      setSubPic(true);
      deleteQuestionUpload({
        variables: {
          uuid,
        },
      });
    });
  };

  const handleUploader = (uuid, file, ext) => {
    changeQuestionUpload({
      variables: {
        uuid,
        ext,
        data: file,
      },
    });
  };

  const handleQuestionsReorder = (data) => {
    setMQuestions(data);
    let weight = 1;
    const newData = [];

    data.forEach((s) => {
      newData.push({
        ...s,
        weight: weight,
      });
      weight++;
    });

    const newQuestions = newData.map((d) => ({
      uuid: d.uuid,
      weight: d.weight,
    }));

    reOrderQuestions({
      variables: {
        input: {
          questions: newQuestions,
        },
      },
    });
  };

  const handleChangeQnType = (val, qid) => {
    setSQnUid(qid);
  };

  const handleResetQnType = (uid) => {
    setSQnUid(null);
  };

  return (
    <>
      {loadng && (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      )}

      {data && (
        <TitleBoxUi
          title={`Quiz Title: ${data.getQuiz.name} | Total Questions: ${data.getQuiz.questions.length}`}
          icon={<List />}
        >
          <div>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                mr: 1,
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                  background: "white",
                },
              }}
            >
              <ArrowBack />
            </IconButton>
            <Button
              onClick={showModal}
              variant="contained"
              sx={{ background: PRIMARY_COLOR }}
              startIcon={<Add />}
            >
              Add Question
            </Button>
          </div>
        </TitleBoxUi>
      )}

      <ModalContainerUi
        width={"50%"}
        title={`Upload Attachment`}
        visible={uploadTach}
        onCancel={() => {
          setUploadTach(false);
          setDataUpload(null);
          window.location = "";
        }}
      >
        <Uploader
          qUid={qnUUID}
          title={null}
          data={dataUpload}
          getBaseData={(data) => null}
          getFileExt={(ext) => null}
          getQnUploadMeta={(uuid, file, ext) => handleUploader(uuid, file, ext)}
        />
      </ModalContainerUi>

      {subPic && <LinearProgress />}

      <ModalContainerUi
        width={"50%"}
        title={`Add Possible Answer`}
        visible={addAnswer}
        onCancel={() => {
          setAddAnswer(false);
          setAnswer("");
        }}
      >
        {/* <EditBox
          description={answer}
          setError={setErrorAnswerAdd}
          error={errorAnswerAdd}
          getEditorContent={(content) => setAnswer(content)}
        /> */}

        {answerSub && <LinearProgress sx={{ mb: 2 }} />}

        <TextField
          value={answer}
          label="Possible Answer"
          onChange={(e) => setAnswer(e.target.value)}
          multiline
          fullWidth
          rows={2}
        />

        <ModalFooter
          sBtn={false}
          onCancel={() => {
            setAddAnswer(false);
            setAnswer("");
          }}
          handleOnClick={() => handleAddPossibleAnswer()}
          loading={false}
        ></ModalFooter>
      </ModalContainerUi>

      <ModalContainerUi
        width={"50%"}
        title={`Edit Possible Answer`}
        visible={editAnswer}
        onCancel={() => {
          setEditAnswer(false);
          setSelectedAnswer();
        }}
      >
        <EditBox
          description={selectedAnswer}
          setError={setErrorAnswerEdit}
          error={errorAnswerEdit}
          getEditorContent={(content) => setSelectedAnswer(content)}
        />
        <ModalFooter
          sBtn={false}
          onCancel={() => {
            setEditAnswer(false);
            setSelectedAnswer();
          }}
          handleOnClick={handleUpdateAnswer}
          loading={aEditLoding}
        ></ModalFooter>
      </ModalContainerUi>

      <ModalContainerUi
        width={"50%"}
        title={`Edit Question Body`}
        visible={editEquestion}
        onCancel={() => {
          setEditQuestion(false);
          setSelectedDescription();
        }}
      >
        <EditBox
          description={selectedDescription}
          setError={setErrorEdit}
          error={errorEdit}
          getEditorContent={(content) => setSelectedDescription(content)}
        />
        <ModalFooter
          sBtn={false}
          onCancel={() => {
            setEditQuestion(false);
            setSelectedDescription();
          }}
          handleOnClick={handleUpdateQuestionBody}
          loading={qnEditLoding}
        ></ModalFooter>
      </ModalContainerUi>

      <Modal
        title="Add Question"
        size="90%"
        opened={visible}
        onClose={() => setVisible(false)}
      >
        <div className="row">
          <div className="col-md-6">
            <EditBox
              setError={setError}
              error={error}
              getEditorContent={(content) => setQuestion(content)}
            />
            <FormControl sx={{ mt: 4, mb: 2 }} fullWidth>
              <InputLabel>Question Type</InputLabel>
              <Select
                value={qnType}
                label="Question Type"
                onChange={handleQnType}
              >
                <MenuItem value={0}></MenuItem>
                <MenuItem value={1}>Single Answer Question</MenuItem>
                <MenuItem value={2}>Multiple Answers Question</MenuItem>
              </Select>
            </FormControl>
            {qnType === 1 ? (
              <SingleAnswerList
                getPossibleAnswers={(data) => setPossibleAnswers(data)}
                getAnswersCount={(items) => console.log(items)}
              />
            ) : qnType === 2 ? (
              <MultipleAnswerList
                getAnswersCount={(items) => console.log(items)}
                getPossibleAnswers={(data) => setPossibleAnswers(data)}
              />
            ) : null}
          </div>
          <div className="col-md-6">
            <Uploader
              getFileExt={(ext) => setExt(ext)}
              getBaseData={(data) => setImageData(data)}
            />
          </div>
        </div>
        <ModalFooter
          onCancel={null}
          loading={loading}
          sBtn={false}
          handleOnClick={() => {
            saveQuestion();
          }}
        />
      </Modal>

      <Divider sx={{ mt: 1, mb: 1 }} />

      {data &&
        (data.getQuiz.questions.length === 0 ? (
          <>
            <Alert severity="error">
              No Questions added, please add questions...
            </Alert>
          </>
        ) : null)}

      {data && (
        <Reorder.Group
          as="div"
          values={mQuestions}
          onReorder={handleQuestionsReorder}
        >
          {mQuestions.map((q, i) => (
            <Reorder.Item as="div" key={q.uuid} value={q}>
              <div className="questions">
                <Paper sx={{ padding: 1, mb: 3 }} elevation={4}>
                  <Paper sx={{ padding: 1, mb: 1 }} elevation={4}>
                    <div
                      className="d-flex justify-content-between"
                      style={{ fontSize: "1rem" }}
                    >
                      <span className="d-flex">
                        <p style={{ paddingRight: 2 }}>{`${i + 1}`})</p>
                        <div>{parser(q.question)}</div>
                      </span>
                      <div className="d-flex">
                        <IconButton
                          onClick={() => handleQuestionEdit(q)}
                          color="primary"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleQuestionRemove(q.uuid)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </div>
                  </Paper>
                  <div className="row">
                    <div className="col-md-12">
                      {q.imageData && (
                        <Paper elevation={0} sx={{ mb: 2, ml: 5, width: 600 }}>
                          <Chip
                            sx={{ mb: 1 }}
                            onClick={() => removeAttachment(q.uuid)}
                            label="Remove Attachment"
                          />
                          <Uploader
                            getBaseData={(data) => null}
                            getFileExt={(ext) => null}
                            title={null}
                            qUid={q.uuid}
                            getQnUploadMeta={(uuid, file, ext) =>
                              handleUploadMeta(uuid, file, ext)
                            }
                            data={q.imageData.split(",")[1]}
                          />
                          {subPic && <LinearProgress />}
                        </Paper>
                      )}

                      <AnswersEditor
                        q={q}
                        handleAnswerChecked={handleAnswerChecked}
                        handleAnswerEdit={handleAnswerEdit}
                        handleAnswerRemove={handleAnswerRemove}
                        changeQnType={handleChangeQnType}
                        resetQnType={handleResetQnType}
                      />

                      {/* {q.answers.map((a, i) => (
                        <Paper
                          key={i}
                          sx={{ padding: 1, ml: 5, mr: 5, mb: 1 }}
                          elevation={4}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {q.qnType === 1 ? (
                                <FormControlLabel
                                  control={<Radio defaultChecked={a.correct} />}
                                  label={`${parser(a.possibleAnswer)} ${
                                    a.correct ? "(CORRECT)" : "(WRONG)"
                                  } `}
                                />
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{ color: "black" }}
                                      defaultChecked={a.correct}
                                      onChange={(e) =>
                                        handleAnswerChecked(e, a.uuid, q.uuid)
                                      }
                                    />
                                  }
                                  label={`${parser(a.possibleAnswer)} ${
                                    a.correct ? "(CORRECT)" : "(WRONG)"
                                  } `}
                                />
                              )}
                            </div>
                            <div className="d-flex">
                              <IconButton
                                color="primary"
                                onClick={() => handleAnswerEdit(a)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleAnswerRemove(a.uuid, q.uuid)
                                }
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </div>
                        </Paper>
                      ))} */}
                      {qnSQnUid !== null ? null : (
                        <div className="d-flex">
                          <Chip
                            onClick={() => {
                              setAnswer("");
                              setAddAnswer(true);
                              setQnUid(q.uuid);
                            }}
                            variant="filled"
                            sx={{
                              padding: 1,
                              ml: 5,
                              mt: 2,
                              mb: 2,
                              background: PRIMARY_COLOR,
                              color: "white",
                              "&:hover": {
                                color: PRIMARY_COLOR,
                                background: "white",
                              },
                            }}
                            label="Add More Answer"
                          />
                          {q.imageData === null ? (
                            <Chip
                              color="primary"
                              sx={{
                                padding: 1,
                                ml: 1,
                                mt: 2,
                                mb: 2,
                                "&:hover": {
                                  color: PRIMARY_COLOR,
                                  background: "white",
                                },
                              }}
                              onClick={() => uploadAttachment(q.uuid)}
                              label="Upload Attachment"
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      )}
    </>
  );
};

export default QuizQuestions;
