import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginLoader from "../../components/loaders/LoginLoader";
import {
  isLoggedInVar,
  onForgotPasswordClickedVar,
  onJoinClickedVar,
  systemInfoVar,
  userPermissions,
} from "../../store/cache";
import {
  ACCESS_TOKEN,
  APP_NAME,
  LOGIN_URL,
  MY_PERMISSIONS,
  PRIMARY_COLOR,
} from "../../utils/constants";
import { showToastTop } from "../../utils/helpers";

import "./Login.css";

import logo from "../../assets/logo.png";
import { Modal } from "@mantine/core";
import { useReactiveVar } from "@apollo/client";
import JoinNow from "../join-now/JoinNow";
import { ForgotPassword } from "../forgot-password/ForgotPassword";

import playStore from "../../assets/google_play.png";

const loginUrl = LOGIN_URL;

const Login = () => {
  const location = useLocation();
  let from = location.state?.from?.pathname || "/dashboard";

  const [loading, setLoading] = useState(false);

  const onJoinClicked = useReactiveVar(onJoinClickedVar);

  const onForgotPasswordClicked = useReactiveVar(onForgotPasswordClickedVar);

  const systemInfo = useReactiveVar(systemInfoVar);

  //const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isLoggedInVar()) {
      navigate(from, { replace: true });
    }
  }, [navigate]);

  // The use of location.state to preserve the previous location so you can send the user there after they authenticate
  //let from = location.state?.from?.pathname || "/";

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const result = await axios.post(loginUrl, {
        ...data,
      });

      if (result.status === 200) {
        setLoading(false);

        localStorage.setItem(
          ACCESS_TOKEN,
          JSON.stringify(result.data.access_token)
        );

        isLoggedInVar(true);

        localStorage.setItem(
          MY_PERMISSIONS,
          JSON.stringify(result.data.permissions)
        );

        userPermissions(result.data.permissions);

        // Navigate User to dashboard
        // The use of navigate("...", { replace: true }) to replace the /login route in the history stack so the user doesn't return to the login page when clicking the back button after logging in

        navigate(from, { replace: true });
        //navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);

      if (
        error.response.request.status &&
        error.response.request.status === 401
      ) {
        showToastTop(`Wrong credentials`);
      } else {
        showToastTop(`${error.message}`);
      }
    }
  };

  return (
    <LoginLoader loading={loading}>
      {!systemInfo.logo ? (
        <LinearProgress sx={{ background: PRIMARY_COLOR }} />
      ) : null}
      <Modal
        centered
        opened={onJoinClicked}
        onClose={() => onJoinClickedVar(false)}
        title=""
        size="lg"
      >
        <JoinNow />
      </Modal>

      <Modal
        centered
        opened={onForgotPasswordClicked}
        onClose={() => onForgotPasswordClickedVar(false)}
        title=""
        size="lg"
      >
        <ForgotPassword />
      </Modal>

      <div className="login-box">
        {systemInfo.logo ? (
          <>
            <Paper
              sx={{
                width: 400,
                height: 500,
                borderRadius: "0.8rem",
              }}
              elevation={12}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {systemInfo.logo && (
                    <>
                      <img
                        src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
                        alt="Logo"
                        style={{
                          marginTop: 10,
                          width: 100,
                        }}
                      />
                    </>
                  )}
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      marginY: 1,
                    }}
                  >
                    {systemInfo && systemInfo.systemTitle}
                  </Typography>

                  <Box
                    sx={{
                      padding: "0 20px",
                    }}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      {...register("email", { required: "Email is required." })}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      {...register("password", {
                        required: "Password is required.",
                      })}
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                    />
                  </Box>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    padding: "0 20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginY: 1,
                  }}
                >
                  <Button
                    type="submit"
                    size="large"
                    sx={{ width: "100%", backgroundColor: PRIMARY_COLOR }}
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>

                {/* <Typography
                sx={{
                  padding: "0 20px",
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link to="#" onClick={() => onJoinClickedVar(true)}>
                  Create New Account
                </Link>
              </Typography> */}

                <Typography
                  sx={{
                    padding: "0 20px",
                    mt: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link to="#" onClick={() => onForgotPasswordClickedVar(true)}>
                    Forgot Your Password?
                  </Link>
                </Typography>
              </form>
            </Paper>
          </>
        ) : (
          <>
            <div>
              <CircularProgress
                sx={{ color: PRIMARY_COLOR }}
                thickness={3}
                size={`10rem`}
              />
            </div>
          </>
        )}
      </div>
    </LoginLoader>
  );
};

export default Login;
