import { Add } from "@mui/icons-material";
import { Button, Chip } from "@mui/material";
import React, { useRef, useState } from "react";
import { infoBox, promptBox } from "../../utils/helpers";
import SingleAnswer from "./SingleAnswer";

const SingleAnswerList = ({ getAnswersCount, getPossibleAnswers }) => {
  const answerRefs = useRef([]);

  const [answers, setAnswers] = useState([1]);
  const [allPossibleAnswers, setAllPossibleAnswers] = useState([]);

  const [selectedPossibleAnswer, setSelectedPossibleAnswer] = useState([]);

  const [emptyChecked, setEmptyChecked] = useState({
    id: null,
    checked: false,
  });

  const addMoreAnswer = () => {
    const n = answers.length + 1;
    const a = [...answers, n];
    setAnswers([...a]);
    getAnswersCount(answers.length);
  };

  const removeAnswer = (id) => {
    if (answers.length === 1) {
      infoBox(`You can not remove this item`);
      return;
    }

    const index = answers.indexOf(id);
    if (index > -1) {
      promptBox(() => {
        answers.splice(index, 1);
        setAnswers([...answers]);

        const newPossibleAnswers = [
          ...allPossibleAnswers.filter((a) => a.id !== id),
        ];

        setAllPossibleAnswers([...newPossibleAnswers]);
        getPossibleAnswers(newPossibleAnswers);
      });
    }
    getAnswersCount(answers.length);
  };

  const notify = (data) => {
    getPossibleAnswers(data);
  };

  const getCheckedPossibleAnswer = (content, id) => {
    setSelectedPossibleAnswer(id);
    const found = allPossibleAnswers.some((pa) => pa.id === id);
    if (!found) {
      if (content) {
        infoBox("Please provide first answer");
        setEmptyChecked({
          id,
          checked: true,
        });
      }
    } else {
      const newPossibleAnswers = allPossibleAnswers.map((pa) => {
        if (pa.id === id) {
          if (content) {
            pa.correct = true;
            return pa;
          } else {
            pa.correct = false;
            return pa;
          }
        } else {
          pa.correct = false;
          return pa;
        }
      });
      notify(newPossibleAnswers);
      setAllPossibleAnswers([...newPossibleAnswers]);
    }
  };

  const getPossibleAnswer = (content, id) => {
    const answer = {
      possibleAnswer: content,
      id,
      correct: false,
    };

    if (emptyChecked.checked && emptyChecked.id === id) {
      answer.correct = true;
    }

    const found = allPossibleAnswers.some((pa) => pa.id === id);
    if (!found) {
      const newPossibleAnswers = [answer, ...allPossibleAnswers];
      notify(newPossibleAnswers);
      setAllPossibleAnswers(newPossibleAnswers);
    } else {
      const answers = allPossibleAnswers.filter((p) => p.id !== id);
      const newPossibleAnswers = [answer, ...answers];
      notify(newPossibleAnswers);
      setAllPossibleAnswers(newPossibleAnswers);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Chip label={`Please provide possible answers for your question`} />
        <Button onClick={addMoreAnswer} size="small" startIcon={<Add />}>
          Add More Answer
        </Button>
      </div>

      {answers.map((a, i) => {
        return (
          <SingleAnswer
            ref={(el) => (answerRefs.current[a] = el)}
            id={a}
            selectedPossibleAnswer={selectedPossibleAnswer}
            handleCheckedPossibleAnswer={(content, id) =>
              getCheckedPossibleAnswer(content, id)
            }
            handlePossibleAnswer={(content, id) => {
              getPossibleAnswer(content, id);
            }}
            handleAnswerRemove={removeAnswer}
            key={i}
          />
        );
      })}
    </>
  );
};

export default SingleAnswerList;
