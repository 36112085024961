import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  Delete,
  Edit,
  ListAlt,
  NotificationAddRounded,
  Save,
} from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import ModalContainerUi from "../../../components/modal-container/ModalContainerUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading, systemInfoVar } from "../../../store/cache";
import { PRIMARY_COLOR } from "../../../utils/constants";
import { promptBox, showToast } from "../../../utils/helpers";
import {
  DELETE_QUIZ_REMINDER,
  GET_ALL_REMINDERS,
  QUIZ_REMINDER_NOW,
  SAVE_QUIZ_REMINDER_DAYS,
} from "../graphQL";
import ReminderUsers from "./ReminderUsers";

const ReminderSettings = () => {
  const [loader, setLoader] = useState(false);
  const [loaderx, setLoaderx] = useState(false);
  const [users, setUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false);

  const systemInfo = useReactiveVar(systemInfoVar);

  const { mutation: saveQuizReminderDays } = useGraphQLMutator(
    SAVE_QUIZ_REMINDER_DAYS,
    "saveQuizReminderDays",
    () => {
      isLoading(false);
    },
    `Successfully saved`,
    () => {
      isLoading(false);
    }
  );

  const { mutation: deleteQuizReminder } = useGraphQLMutator(
    DELETE_QUIZ_REMINDER,
    "deleteQuizReminder",
    [GET_ALL_REMINDERS],
    () => {
      setLoaderx(false);
    },
    `Successfully deleted`,
    () => {
      setLoaderx(false);
    }
  );

  const { mutation: runQuizReminderNow } = useGraphQLMutator(
    QUIZ_REMINDER_NOW,
    "runQuizReminderNow",
    [GET_ALL_REMINDERS],
    () => {
      setLoaderx(false);
    },
    `Successfully reminded`,
    () => {
      setLoaderx(false);
    }
  );

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    isLoading(true);
    saveQuizReminderDays({
      variables: {
        days: parseFloat(data.days),
      },
    });
  };

  useEffect(() => {
    if (systemInfo) {
      setValue("days", systemInfo.quizReminderDays);
    }
  }, [systemInfo]);

  const handleReminder = () => {
    promptBox(() => {
      isLoading(true);
      runQuizReminderNow();
    }, null);
  };

  const handleViewUsersReminded = (rec) => {
    setUsers(rec.userReminders);
    setShowUsers(true);
  };

  const handleDeleteReminder = (uid) => {
    promptBox(() => {
      setLoaderx(true);
      deleteQuizReminder({
        variables: {
          uid,
        },
      });
    }, null);
  };

  const colums = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Quiz",
      key: "quiz",
      render: (_, rec) => rec.quiz.name,
    },
    {
      title: "Total User Reminded",
      dataIndex: "totalUserCountReminded",
      key: "totalUserCountReminded",
    },
    {
      title: "Date Reminded",
      dataIndex: "reminderDate",
      key: "reminderDate",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, rec) => (
        <Space>
          <ActionBtn
            onClickIcon={() => handleDeleteReminder(rec.uuid)}
            icon={<Delete color="error" />}
            title="Delete Reminder"
          />
          <ActionBtn
            onClickIcon={() => handleViewUsersReminded(rec)}
            icon={<ListAlt color="info" />}
            title="View Users Reminded"
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <ModalContainerUi
        onCancel={() => setShowUsers(false)}
        visible={showUsers}
        width={800}
        top={200}
        title={`Users Reminded`}
      >
        {users.length > 0 && <ReminderUsers users={users} />}
      </ModalContainerUi>

      <TitleBoxUi title={`Quiz Reminder Settings`} icon={<Edit />}>
        <Button
          onClick={() => handleReminder()}
          variant="contained"
          sx={{ background: PRIMARY_COLOR }}
          startIcon={<NotificationAddRounded />}
        >
          Remind Now!
        </Button>
      </TitleBoxUi>
      {isLoading() && <LinearProgress />}
      {loader && <LinearProgress />}
      <Paper sx={{ mt: 1, padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Typography>
              After how many days should be reminded to take a quiz for those
              who did not attempt after published date
            </Typography>
            <TextField
              margin="normal"
              focused
              label="Days"
              name="days"
              {...register("days", {
                required: "Days are required.",
              })}
              error={Boolean(errors.days)}
              helperText={errors.days?.message}
            />
          </div>
          <div>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="filled"
              sx={{
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                },
              }}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </Paper>

      <Paper sx={{ padding: 2, mt: 2 }}>
        {loaderx && <LinearProgress />}
        <DataTableUi
          columns={colums}
          query={GET_ALL_REMINDERS}
          queryName="getAllQuizReminders"
          loadingData={(loading) => setLoader(loading)}
        />
      </Paper>
    </>
  );
};

export default ReminderSettings;
