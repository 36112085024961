import { useQuery } from "@apollo/client";
import {
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBoxRounded,
  List,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Paper,
  Radio,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { PRIMARY_COLOR } from "../../utils/constants";
import { GET_QUIZ } from "../quiz/graphQL";

import parser from "html-react-parser";
import Uploader from "../../components/uploader/Uploader";

const QuizPreview = () => {
  const { uid } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_QUIZ, {
    variables: {
      uid,
    },
  });

  return (
    <>
      {data && (
        <TitleBoxUi
          title={`Quiz Title: ${data.getQuiz.name} | Total Questions: ${data.getQuiz.questions.length}`}
          icon={<List />}
        >
          <div>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                mr: 1,
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                  background: "white",
                },
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        </TitleBoxUi>
      )}

      {loading && (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      )}

      <Divider sx={{ mt: 1, mb: 1 }} />

      {data &&
        (data.getQuiz.questions.length === 0 ? (
          <>
            <Alert severity="error">
              No Questions added, please add questions...
            </Alert>
          </>
        ) : null)}

      {data &&
        data.getQuiz.questions.map((q, i) => (
          <div className="questions">
            <Paper sx={{ padding: 1, mb: 3 }} elevation={4}>
              <Paper sx={{ padding: 1, mb: 1 }} elevation={4}>
                <div
                  className="d-flex justify-content-between"
                  style={{ fontSize: "1rem" }}
                >
                  <span className="d-flex">
                    <p style={{ paddingRight: 2 }}>{`${i + 1}`})</p>
                    <div>{parser(q.question)}</div>
                  </span>
                </div>
              </Paper>
              <div className="row">
                <div className="col-md-12">
                  {q.imageData && (
                    <Paper elevation={0} sx={{ mb: 2, ml: 5, width: 600 }}>
                      <Uploader
                        getBaseData={(data) => null}
                        getFileExt={(ext) => null}
                        title={null}
                        qUid={q.uuid}
                        getQnUploadMeta={(uuid, file, ext) => null}
                        data={q.imageData.split(",")[1]}
                      />
                    </Paper>
                  )}

                  {q.answers.map((a, i) => (
                    <Paper
                      key={i}
                      sx={{ padding: 2, ml: 5, mr: 5, mb: 1 }}
                      elevation={4}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {q.qnType === 1 ? (
                            <FormControlLabel
                              control={
                                a.correct ? (
                                  <RadioButtonChecked sx={{ mr: 1 }} />
                                ) : (
                                  <RadioButtonUnchecked sx={{ mr: 1 }} />
                                )
                              }
                              label={`${parser(a.possibleAnswer)} ${
                                a.correct ? "(CORRECT)" : "(WRONG)"
                              } `}
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                a.correct ? (
                                  <CheckBoxRounded sx={{ mr: 1 }} />
                                ) : (
                                  <CheckBoxOutlineBlank sx={{ mr: 1 }} />
                                )
                              }
                              label={`${parser(a.possibleAnswer)} ${
                                a.correct ? "(CORRECT)" : "(WRONG)"
                              } `}
                            />
                          )}
                        </div>
                      </div>
                    </Paper>
                  ))}
                </div>
              </div>
            </Paper>
          </div>
        ))}
    </>
  );
};

export default QuizPreview;
