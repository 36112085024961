import { useLazyQuery, useQuery } from "@apollo/client";
import {
  PublishOutlined,
  ResetTvOutlined,
  ScoreOutlined,
  Scoreboard,
  Settings,
} from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading, systemInfoVar } from "../../../store/cache";
import { GET_SYSTEM_INFO } from "../../login/graphQL";
import { SAVE_EQAP } from "../graphQL";
import { GET_ALL_USERS } from "../../users/graphQL";
import { useForm } from "react-hook-form";
import ModalFooter from "../../../components/modal-footer/ModalFooter";
import {
  GET_ALL_QUIZES,
  PUBLISH_QUIZ_FOR_USER,
  PUBLISH_QUIZ_RESULTS_FOR_USER,
} from "../../quiz/graphQL";
import { showToast, showToastTop } from "../../../utils/helpers";

export const QuizSettings = () => {
  const { data, loading } = useQuery(GET_SYSTEM_INFO);
  const [checked, setChecked] = React.useState(true);

  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserQuiz, setSelectedUserQuiz] = useState();
  const [selectedQuiz, setSelectedQuiz] = useState();
  const [openedQuiz, setOpenedQuiz] = useState(false);
  const [openedUserQuiz, setOpenedUserQuiz] = useState(false);
  const [opened, setOpened] = useState(false);

  const [getSystemInfo, { loading: lazyLoading, data: lazyData }] =
    useLazyQuery(GET_SYSTEM_INFO, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (lazyData && !lazyLoading) {
      isLoading(false);
      systemInfoVar(lazyData.getSystemInfoSettings);
    }
    if (lazyLoading) {
      isLoading(true);
    }
  }, [lazyData, lazyLoading]);

  useEffect(() => {
    if (data) {
      setChecked(data.getSystemInfoSettings.canEditQuizAfterPublished);
    }
  }, [data]);

  const { mutation: saveCanEditQuizAfterPublished } = useGraphQLMutator(
    SAVE_EQAP,
    "saveCanEditQuizAfterPublished",
    [],
    () => {
      isLoading(false);
    },
    `Successfully saved`,
    () => {
      getSystemInfo();
      isLoading(false);
    }
  );

  const updateEQAP = () => {
    isLoading(true);
    if (data) {
      saveCanEditQuizAfterPublished({
        variables: {
          editable: !data.getSystemInfoSettings.canEditQuizAfterPublished,
        },
      });
    }
  };

  const { data: users } = useQuery(GET_ALL_USERS);

  const { data: quizes } = useQuery(GET_ALL_QUIZES);

  const formSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
  });

  const formSchemaQuiz = Yup.object().shape({
    fullNameQuiz: Yup.string().required("Full Name is required"),
    quiz: Yup.string().required("Quiz is required"),
  });

  useEffect(() => {
    return () => {
      setOpened(false);
    };
  }, []);

  const formOptions = { resolver: yupResolver(formSchema) };
  const formOptionsQuiz = { resolver: yupResolver(formSchemaQuiz) };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  //---------

  const {
    handleSubmit: hs,
    register: rg,
    formState: { errors: errs },
    reset: r,
  } = useForm(formOptionsQuiz);

  const { mutation: publishQuizForUser } = useGraphQLMutator(
    PUBLISH_QUIZ_FOR_USER,
    "publishQuizForUser",
    [],
    () => {
      isLoading(false);
      showToastTop("Successfully published all previous quized", false);
    },
    "Successfully published all previous quized",
    () => {},
    false,
    false
  );

  const { mutation: publishQuizResultsForUser } = useGraphQLMutator(
    PUBLISH_QUIZ_RESULTS_FOR_USER,
    "publishQuizResultsForUser",
    [],
    () => {
      isLoading(false);
      showToastTop("Successfully quiz results were published", false);
    },
    "Successfully quiz results were published",
    () => {},
    false,
    false
  );

  const onSubmit = async (data) => {
    if (users) {
      const cachedUsers = users.getAllUsers;
      const user = cachedUsers.filter((u) => u.fullName === data.fullName)[0];
      const useruuid = user.uuid;
      isLoading(true);
      publishQuizForUser({
        variables: {
          useruuid,
        },
      });
    }
  };

  const onSubmitUserQuiz = async (data) => {
    if (users && quizes) {
      const cachedUsers = users.getAllUsers;
      const user = cachedUsers.filter(
        (u) => u.fullName === data.fullNameQuiz
      )[0];
      const useruuid = user.uuid;

      const cachedQuizes = quizes.getQuizes;
      const quiz = cachedQuizes.filter((u) => u.name === data.quiz)[0];
      const quizuuid = quiz.uuid;
      isLoading(true);

      publishQuizResultsForUser({
        variables: {
          useruuid,
          quizuuid,
        },
      });
    }
  };

  return (
    <>
      <TitleBoxUi title={`Quiz Settings`} icon={<Settings />}>
        {data && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  updateEQAP();
                  setChecked(event.target.checked);
                }}
                checked={checked}
              />
            }
            label="Quiz Editable After Publication"
          />
        )}
      </TitleBoxUi>

      <Paper sx={{ padding: 2, mt: 2 }} elevation={4}>
        <p>
          <PublishOutlined sx={{ fontSize: 25 }} /> Publish Previous Quizes
        </p>
        <Divider sx={{ mb: 2 }} />
        {!users && <LinearProgress />}
        <div className="row">
          <div className="col-md-4">
            {users && (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {
                    <Autocomplete
                      open={opened}
                      onFocus={() => setOpened(true)}
                      sx={{ mt: 1, mb: 1 }}
                      options={users.getAllUsers.map((user) => user.fullName)}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                        setOpened(false);
                      }}
                      onBlur={() => {
                        setOpened(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...register("fullName")}
                          error={Boolean(errors.fullName)}
                          helperText={errors.fullName?.message}
                          {...params}
                          label="Select User"
                          variant="outlined"
                        />
                      )}
                    />
                  }

                  <ModalFooter
                    text="Publish Previous Quizes"
                    onCancel={() => {
                      reset();
                    }}
                  />
                </form>
              </>
            )}
          </div>
        </div>
      </Paper>

      <Paper sx={{ padding: 2, mt: 2 }} elevation={4}>
        <p>
          <ResetTvOutlined sx={{ fontSize: 25 }} /> Publish Results
        </p>
        <Divider sx={{ mb: 2 }} />
        {!users && <LinearProgress />}
        <form onSubmit={hs(onSubmitUserQuiz)}>
          <div className="row">
            <div className="col-md-6">
              {users && (
                <>
                  {
                    <Autocomplete
                      open={openedUserQuiz}
                      onFocus={() => setOpenedUserQuiz(true)}
                      sx={{ mt: 1, mb: 1 }}
                      options={users.getAllUsers.map((user) => user.fullName)}
                      value={selectedUserQuiz}
                      onChange={(event, newValue) => {
                        setSelectedUserQuiz(newValue);
                        setOpenedUserQuiz(false);
                      }}
                      onBlur={() => {
                        setOpenedUserQuiz(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...rg("fullNameQuiz")}
                          error={Boolean(errs.fullNameQuiz)}
                          helperText={errs.fullNameQuiz?.message}
                          {...params}
                          label="Select User"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                </>
              )}
            </div>
            <div className="col-md-6">
              {quizes && (
                <>
                  {
                    <Autocomplete
                      open={openedQuiz}
                      onFocus={() => setOpenedQuiz(true)}
                      sx={{ mt: 1, mb: 1 }}
                      options={quizes.getQuizes.map((quiz) => quiz.name)}
                      value={selectedQuiz}
                      onChange={(event, newValue) => {
                        setSelectedQuiz(newValue);
                        setOpenedQuiz(false);
                      }}
                      onBlur={() => {
                        setOpenedQuiz(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...rg("quiz")}
                          error={Boolean(errs.quiz)}
                          helperText={errs.quiz?.message}
                          {...params}
                          label="Select Quiz"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ModalFooter
                text="Publish Quiz Results"
                onCancel={() => {
                  reset();
                }}
              />
            </div>
          </div>
        </form>
      </Paper>
    </>
  );
};
