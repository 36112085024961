import { useQuery } from "@apollo/client";
import {
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBoxRounded,
  List,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Paper,
  Radio,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { PRIMARY_COLOR } from "../../utils/constants";
import { GET_MY_QUIZ } from "../quiz/graphQL";

import parser from "html-react-parser";
import Uploader from "../../components/uploader/Uploader";

const QuizView = () => {
  const [selectedUuid, setSelectedUuid] = useState();

  const { uid } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_MY_QUIZ, {
    variables: {
      uid,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      {loading && (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      )}
      {data && (
        <TitleBoxUi
          title={`Quiz Title: ${data.getMyQuiz?.name} | Total Questions: ${data.getMyQuiz?.questions?.length}`}
          icon={<List />}
        >
          <div>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                mr: 1,
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                  background: "white",
                },
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        </TitleBoxUi>
      )}

      {data && (
        <>
          {data.getMyQuiz.questions.map((q, i) => (
            <div className="questions" key={i}>
              <Paper sx={{ padding: 1, mb: 3, mt: 3 }} elevation={4}>
                <Paper sx={{ padding: 1, mb: 1 }} elevation={4}>
                  <div
                    className="d-flex justify-content-between"
                    style={{ fontSize: "1rem" }}
                  >
                    <span className="d-flex">
                      <p style={{ paddingRight: 2 }}>{`${i + 1}`})</p>
                      <div>{parser(q.question)}</div>
                    </span>
                  </div>
                </Paper>
                <div className="row">
                  <div className="col-md-12">
                    {q.imageData && (
                      <Paper elevation={0} sx={{ mb: 2, ml: 5, width: 600 }}>
                        <Uploader
                          getBaseData={(data) => null}
                          getFileExt={(ext) => null}
                          title={null}
                          qUid={q.uuid}
                          getQnUploadMeta={(uuid, file, ext) => null}
                          data={q.imageData.split(",")[1]}
                        />
                      </Paper>
                    )}

                    {q.myAnswers.map((a, i) => (
                      <Paper
                        key={i}
                        sx={{ padding: 2, ml: 5, mr: 5, mb: 1 }}
                        elevation={4}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {q.qnType === 1 ? (
                              <FormControlLabel
                                control={
                                  a.myAttempt ? (
                                    <RadioButtonChecked sx={{ mr: 1 }} />
                                  ) : (
                                    <RadioButtonUnchecked sx={{ mr: 1 }} />
                                  )
                                }
                                label={`${parser(a.possibleAnswer)}`}
                              />
                            ) : (
                              <FormControlLabel
                                control={
                                  a.myAttempt ? (
                                    <CheckBoxRounded sx={{ mr: 1 }} />
                                  ) : (
                                    <CheckBoxOutlineBlank sx={{ mr: 1 }} />
                                  )
                                }
                                label={`${parser(a.possibleAnswer)}`}
                              />
                            )}
                          </div>
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>
              </Paper>
            </div>
          ))}
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
    </>
  );
};

export default QuizView;
