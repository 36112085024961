import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
} from "@mui/material";
import { Reorder } from "framer-motion";
import React, { useEffect, useState } from "react";

import parser from "html-react-parser";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import {
  CHANGE_SINGLE_ANSWER_CORRECT,
  GET_QUIZ,
  RE_ORDER_ANSWERS,
} from "../../pages/quiz/graphQL";
import { isLoading } from "../../store/cache";
import { Select } from "antd";

const AnswersEditor = ({
  q,
  handleAnswerChecked,
  handleAnswerEdit,
  handleAnswerRemove,
  changeQnType,
  resetQnType,
}) => {
  const [selectQn, setSelectQn] = useState(0);
  const [mAnswers, setMAnswers] = useState([]);
  const [selectedPossibleAnswer, setSelectedPossibleAnswer] = useState([]);
  const [qnType, setQnType] = useState(() => {
    return q.qnType;
  });

  const { mutation: reOrderAnswers } = useGraphQLMutator(
    RE_ORDER_ANSWERS,
    "reOrderAnswers",
    [GET_QUIZ],
    () => {
      isLoading(false);
    },
    null,
    () => {},
    false,
    false
  );

  const { mutation: changeSingleQuestionAnswerCorrect } = useGraphQLMutator(
    CHANGE_SINGLE_ANSWER_CORRECT,
    "changeSingleQuestionAnswerCorrect",
    [GET_QUIZ],
    () => {
      isLoading(false);
    },
    null,
    () => {},
    false,
    false
  );

  useEffect(() => {
    if (q) {
      setMAnswers(q.answers);
      q.answers.forEach((a) => {
        if (a.correct) {
          setSelectedPossibleAnswer(a.uuid);
        }
      });
    }
  }, [q]);

  const handleChangeQuestionType = (val) => {
    setSelectQn(val);
    changeQnType(val, q.uuid);
    setQnType(val);
  };

  const handleAnswersReorder = (data) => {
    setMAnswers(data);

    let weight = 1;
    const newData = [];

    data.forEach((s) => {
      newData.push({
        ...s,
        weight: weight,
      });
      weight++;
    });

    const newAnswers = newData.map((d) => ({
      uuid: d.uuid,
      weight: d.weight,
    }));

    reOrderAnswers({
      variables: {
        input: {
          answers: newAnswers,
        },
      },
    });
  };

  const handleRadioEditAnswer = (e, id, changeQnType = 0) => {
    setSelectedPossibleAnswer(id);
    changeSingleQuestionAnswerCorrect({
      variables: {
        quuid: q.uuid,
        auuid: id,
        qnatype: changeQnType,
      },
    });
  };

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          gap: 2,
          flexDirection: "row-reverse",
          padding: 1,
          ml: 3,
          mr: 4,
          mb: 1,
        }}
        elevation={0}
      >
        <Select
          onChange={handleChangeQuestionType}
          defaultValue={qnType}
          value={qnType}
          options={[
            {
              value: 1,
              label: "Single Answer",
            },
            {
              value: 2,
              label: "Multiple Answers",
            },
          ]}
        />
        <b>Change Answer Type:</b>
      </Paper>

      <div
        style={
          selectQn !== 0 ? { backgroundColor: "#ccc", padding: "20px" } : {}
        }
      >
        <Reorder.Group
          as="div"
          values={mAnswers}
          onReorder={handleAnswersReorder}
        >
          {mAnswers.map((a, i) => (
            <Reorder.Item as="div" key={i} value={a}>
              {selectQn === 0 ? (
                <Paper sx={{ padding: 1, ml: 5, mr: 5, mb: 1 }} elevation={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {q.qnType === 1 ? (
                        <FormControlLabel
                          control={
                            <Radio
                              name={`possibleAnswer`}
                              checked={selectedPossibleAnswer === a.uuid}
                              onChange={(e) => handleRadioEditAnswer(e, a.uuid)}
                              value={a.uuid}
                            />
                          }
                          label={`${parser(a.possibleAnswer)} ${
                            a.correct ? "(CORRECT)" : "(WRONG)"
                          } `}
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ color: "black" }}
                              defaultChecked={a.correct}
                              onChange={(e) =>
                                handleAnswerChecked(e, a.uuid, q.uuid)
                              }
                            />
                          }
                          label={`${parser(a.possibleAnswer)} ${
                            a.correct ? "(CORRECT)" : "(WRONG)"
                          } `}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                      <IconButton
                        color="primary"
                        onClick={() => handleAnswerEdit(a)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleAnswerRemove(a.uuid, q.uuid)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                </Paper>
              ) : (
                <Paper sx={{ padding: 1, ml: 5, mr: 5, mb: 1 }} elevation={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {selectQn === 1 ? (
                      <FormControlLabel
                        control={
                          <Radio
                            name={`possibleAnswer`}
                            checked={selectedPossibleAnswer === a.uuid}
                            onChange={(e) =>
                              handleRadioEditAnswer(e, a.uuid, selectQn)
                            }
                            value={a.uuid}
                          />
                        }
                        label={`${parser(a.possibleAnswer)} ${
                          a.correct ? "(CORRECT)" : "(WRONG)"
                        } `}
                      />
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ color: "black" }}
                            defaultChecked={a.correct}
                            onChange={(e) =>
                              handleAnswerChecked(e, a.uuid, q.uuid, selectQn)
                            }
                          />
                        }
                        label={`${parser(a.possibleAnswer)} ${
                          a.correct ? "(CORRECT)" : "(WRONG)"
                        } `}
                      />
                    )}
                  </div>
                </Paper>
              )}
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>

      {selectQn !== 0 ? (
        <Paper
          sx={{ padding: 1, ml: 4, mr: 5, mb: 1, display: "flex", gap: 2 }}
          elevation={0}
        >
          <Button
            onClick={() => {
              setSelectQn(0);
              resetQnType(q.uuid);
              setQnType(q.qnType);
            }}
            variant="contained"
            color="success"
            startIcon={<Save />}
          >
            Save & Close
          </Button>
        </Paper>
      ) : null}
    </>
  );
};

export default AnswersEditor;
