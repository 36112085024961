import { gql } from "@apollo/client";

export const GET_EMAIL_ERRORS = gql`
  query getEmailErrors {
    getEmailErrors {
      uuid
      email
      message
      description
      createdAt
    }
  }
`;

export const GET_FCM_ERRORS = gql`
  query getFcmErrors {
    getFcmErrors {
      uuid
      createdAt
      message
      description
    }
  }
`;

export const DELETE_FCM_ERROR = gql`
  mutation deleteFcmError($uuid: String!) {
    deleteFcmError(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const DELETE_EMAIL_ERROR = gql`
  mutation deleteEmailError($uuid: String!) {
    deleteEmailError(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;
