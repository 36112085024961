import { gql } from "@apollo/client";

export const GET_ALL_REGISTERED_TOKEN = gql`
  query getAllRegTokens {
    getAllRegTokens {
      uuid
      token
      email
      createdAt
    }
  }
`;

export const DELETE_REGISTRED_TOKEN = gql`
  mutation deletePushNotificationToken($uuid: String!) {
    deletePushNotificationToken(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
    }
  }
`;
