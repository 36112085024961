import { BarChart } from "@mui/icons-material";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import TitleBoxUi from "../title-box/TitleBoxUi";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Paper } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const QuizResultsChart = () => {
  const getFiveYearAgo = () => {
    const currentYear = new Date().getFullYear();
    const stop = currentYear - 4;
    const step = -1;
    const start = currentYear;
    return Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  };

  const options = useCallback(() => {
    return getFiveYearAgo().map((y) => {
      return {
        value: y,
        label: y,
      };
    });
  }, []);

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const opts = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Your Score",
        data: labels.map(() => 0),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Quiz Score",
        data: labels.map(() => 0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <>
      <TitleBoxUi icon={<BarChart />} title={`Yearly Quiz Results Report`}>
        <Select
          size="large"
          defaultValue={getFiveYearAgo()[0]}
          onChange={handleChange}
          style={{
            width: 200,
          }}
          options={options()}
        />
      </TitleBoxUi>
      <Paper sx={{ mt: 2, padding: 2 }} elevation={5}>
        <Bar options={opts} data={data} />
      </Paper>
    </>
  );
};

export default QuizResultsChart;
