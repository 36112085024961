import { ChatRounded, PieChart } from "@mui/icons-material";
import React from "react";
import TitleBoxUi from "../title-box/TitleBoxUi";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Paper } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const AttempVsUnAttemptChart = ({ myattemptedQuiz, myUnattemptedQuiz }) => {
  const data = {
    labels: ["Attempted Quizzes", "Un-Attempted Quizzes"],
    datasets: [
      {
        label: "# of Votes",
        data: [myattemptedQuiz, myUnattemptedQuiz],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <TitleBoxUi
        icon={<PieChart />}
        title={`Attemps vs Un-attempts Quizzes`}
      ></TitleBoxUi>
      <Paper sx={{ mt: 3, pl: 15, pr: 15, pb: 7, pt: 5 }} elevation={0}>
        <Doughnut data={data} />
      </Paper>
    </>
  );
};

export default AttempVsUnAttemptChart;
