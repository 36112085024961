import { gql } from "@apollo/client";

export const GET_ALL_DEPARTMENTS = gql`
  query getAllDepartments {
    getAllDepartments {
      uuid
      name
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($input: DepartmentInput!) {
    createDepartment(input: $input) {
      code
      errorDescription
      status
      data {
        uuid
      }
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($uid: String!) {
    deleteDepartment(uid: $uid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($uuid: String!, $input: DepartmentInput!) {
    updateDepartment(uuid: $uuid, input: $input) {
      code
      data {
        uuid
      }
      status
      errorDescription
    }
  }
`;
