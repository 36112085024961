import { Warning } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { Tabs } from "antd";
import React from "react";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import SystemEmailError from "./email/SystemEmailError";
import { SystemFCMError } from "./fcm/SystemFCMError";

const SystemErrors = () => {
  return (
    <>
      <TitleBoxUi title={`System Errors`} icon={<Warning />}></TitleBoxUi>
      <Paper sx={{ mt: 2, padding: 2 }}>
        <Tabs defaultActiveKey="1" tabPosition="left">
          <Tabs.TabPane tab="Email Errors" key="1">
            <SystemEmailError />
          </Tabs.TabPane>
          <Tabs.TabPane tab="FCM Errors" key="2">
            <SystemFCMError />
          </Tabs.TabPane>
        </Tabs>
      </Paper>
    </>
  );
};

export default SystemErrors;
