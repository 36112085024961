import { useQuery } from "@apollo/client";
import { Delete, FileOpenOutlined } from "@mui/icons-material";
import { Chip, LinearProgress, Paper } from "@mui/material";
import { Space } from "antd";
import React, { useState } from "react";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import ModalContainerUi from "../../../components/modal-container/ModalContainerUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading } from "../../../store/cache";
import { promptBox } from "../../../utils/helpers";
import {
  DELETE_EMAIL_ERROR,
  GET_EMAIL_ERRORS,
  GET_FCM_ERRORS,
} from "../graphQL";

const SystemEmailError = () => {
  const [viewMoreModal, setViewMoreModal] = useState(false);
  const [details, setDetails] = useState();
  const { loading, data } = useQuery(GET_EMAIL_ERRORS);

  const { mutation: deleteEmailError } = useGraphQLMutator(
    DELETE_EMAIL_ERROR,
    "deleteEmailError",
    [GET_EMAIL_ERRORS],
    () => {
      isLoading(false);
    }
  );

  const viewMore = (desc) => {
    setDetails(desc);
    setViewMoreModal(true);
  };

  const deleteLog = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteEmailError({
        variables: {
          uuid,
        },
      });
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email Source ",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Error Message",
      dataIndex: "message",
      key: "message",
      render: (_, rec) => {
        return <Chip label={`${rec.message}`} />;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <Space>
            <ActionBtn
              title="View More"
              onClickIcon={() => viewMore(rec.description)}
              icon={<FileOpenOutlined color="info" fontSize="inherit" />}
            />
            <ActionBtn
              title="Delete Log"
              onClickIcon={() => deleteLog(rec.uuid)}
              icon={<Delete color="error" fontSize="inherit" />}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalContainerUi
        visible={viewMoreModal}
        title={`Error Details`}
        top
        width={500}
        onCancel={() => setViewMoreModal(false)}
      >
        {details}
      </ModalContainerUi>
      <TitleBoxUi title={`Email Errors`}></TitleBoxUi>
      {loading && <LinearProgress />}
      {data && (
        <DataTableUi
          columns={columns}
          query={GET_EMAIL_ERRORS}
          queryName="getEmailErrors"
        />
      )}
    </>
  );
};

export default SystemEmailError;
