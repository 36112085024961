import { Alert, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import { PRIMARY_COLOR } from "../../utils/constants";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="container">
        <Alert
          color="error"
          sx={{
            mt: 3,
            textAlign: "center",
            mb: 3,
          }}
          severity="error"
        >
          404 Page Not Found
        </Alert>
        <center>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              background: PRIMARY_COLOR,
              color: "white",
              "&:hover": {
                background: "white",
                color: PRIMARY_COLOR,
              },
            }}
          >
            Go Back
          </Button>
        </center>
      </div>
    </>
  );
};

export default NotFound;
