import { gql } from "@apollo/client";

export const UN_ATTEMPTED_QUIZS = gql`
  query getMyNewQuizs($attempted: Boolean!) {
    getMyNewQuizs(attempted: $attempted) {
      uuid
    }
  }
`;

export const GET_DASH_QUIZ_RESULTS = gql`
  query getDashMyQuizResults {
    getDashMyQuizResults {
      uuid
      name
      description
      questions {
        uuid
      }
      correctCount
      totalCount
      publishedAt
      createdAt
    }
  }
`;

export const GET_DASH_NEW_USERS = gql`
  query getDashNewUsers {
    getDashNewUsers
  }
`;

export const GET_DASH_QUIZES = gql`
  query getDashQuizes {
    getDashQuizes
  }
`;

export const GET_DASH_ALL_USERS = gql`
  query getDashAllUsers {
    getDashAllUsers
  }
`;
