import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, LinearProgress, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import { Key } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { showToastTop } from "../../utils/helpers";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { FRONT_END_RESET_PASSWORD } from "./graphq";
import { PRIMARY_COLOR } from "../../utils/constants";
import { useReactiveVar } from "@apollo/client";
import { systemInfoVar } from "../../store/cache";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);

  const systemInfo = useReactiveVar(systemInfoVar);

  const { token } = useParams();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const { mutation: frontEndResetPassword } = useGraphQLMutator(
    FRONT_END_RESET_PASSWORD,
    "frontEndResetPassword",
    [],
    () => {
      setLoading(false);
    },
    "Successfully resetted!, you can now login",
    () => {
      navigate("/");
    },
    true
  );

  const onSubmit = (data) => {
    if (!token) {
      showToastTop("Access expired!");
      navigate("/");
      return;
    }

    setLoading(true);

    frontEndResetPassword({
      variables: {
        token,
        password: data.password,
      },
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Paper
          elevation={0}
          sx={{ padding: 2, width: 500, mt: 3, ml: 1, mr: 1 }}
        >
          <TitleBoxUi title="Reset Your Password" icon={<Key />}></TitleBoxUi>

          {loading && <LinearProgress />}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {systemInfo.logo && (
                <img
                  src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
                  alt="Logo"
                  style={{
                    marginTop: 25,
                    width: 150,
                  }}
                />
              )}
            </div>
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              name="password"
              type="password"
              {...register("password")}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              {...register("confirmPassword")}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
            />

            <Button
              type="submit"
              size="large"
              sx={{ width: "100%", backgroundColor: PRIMARY_COLOR, mt: 2 }}
              variant="contained"
            >
              Reset Now
            </Button>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default PasswordReset;
