import {
  EmojiEvents,
  FactCheck,
  FilterList,
  ListAltOutlined,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import { GET_DASH_QUIZ_RESULTS } from "../graphQL";

function DashQuizResults() {
  const navigate = useNavigate();

  const handleViewMyResults = (uuid) => {
    navigate(`/my-results/${uuid}`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Score",
      key: "score",
      render: (_, rec) => {
        return (
          <Chip
            color="success"
            label={`${rec.correctCount} / ${rec.totalCount}`}
            size="medium"
          />
        );
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <ActionBtn
            onClickIcon={() => handleViewMyResults(rec.uuid)}
            title={`View Your Results`}
            icon={<ListAltOutlined color="info" />}
          />
        );
      },
    },
  ];

  return (
    <>
      <TitleBoxUi
        title="Quizs with Results out"
        icon={<EmojiEvents />}
      ></TitleBoxUi>
      <DataTableUi
        columns={columns}
        query={GET_DASH_QUIZ_RESULTS}
        queryName="getDashMyQuizResults"
      />
    </>
  );
}

export default DashQuizResults;
