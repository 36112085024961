import { useQuery } from "@apollo/client";
import {
  ArrowBack,
  ArrowBackOutlined,
  ArrowBackRounded,
  Edit,
  ExpandMoreRounded,
  FormatListBulletedOutlined,
  Info,
  PlaylistAddCheck,
  Save,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Fab,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading } from "../../../store/cache";
import { PRIMARY_COLOR } from "../../../utils/constants";
import { ASSIGN_DEPARTMENTS, GET_SITE } from "../graphQL";
import SiteDepartmentManagerUi from "../site-department-manager-ui/SiteDepartmentManagerUi";

const SitesDetail = () => {
  const navigate = useNavigate();
  const parms = useParams();

  const [depts, setDepts] = useState([]);
  const [edit, setEdit] = useState(false);

  const { loading, data } = useQuery(GET_SITE, {
    variables: {
      uuid: parms.uuid,
    },
  });

  const { mutation: assignDepartments } = useGraphQLMutator(
    ASSIGN_DEPARTMENTS,
    "assignDepartments",
    [GET_SITE],
    () => isLoading(false),
    null,
    () => setEdit(false)
  );

  useEffect(() => {
    isLoading(loading);
  }, [loading]);

  const assignDepts = () => {
    isLoading(true);
    assignDepartments({
      variables: {
        input: {
          siteUUID: parms.uuid,
          departmentUUIDs: depts,
        },
      },
    });
  };

  return (
    <>
      {data && (
        <>
          <TitleBoxUi title={`Site: ${data.getSite.name}`} icon={<Info />}>
            <IconButton
              sx={{ color: PRIMARY_COLOR }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackRounded />
            </IconButton>
          </TitleBoxUi>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Paper sx={{ padding: 1, mb: 3 }}>
            <div className="row">
              <div className="col">
                <h6> Name</h6>
                <p>{data.getSite.name}</p>
              </div>
              <div className="col">
                <h6>Description</h6>
                <p>{data.getSite.description}</p>
              </div>
            </div>
          </Paper>
          <Divider sx={{ mt: 1, mb: 1 }} />

          <TitleBoxUi
            title={`Departments of Site: ${data.getSite.name}`}
            icon={<PlaylistAddCheck />}
          >
            {!edit ? (
              <IconButton
                sx={{ color: PRIMARY_COLOR }}
                onClick={() => setEdit(true)}
              >
                <Edit />
              </IconButton>
            ) : (
              <IconButton
                sx={{ color: PRIMARY_COLOR }}
                onClick={() => setEdit(false)}
              >
                <FormatListBulletedOutlined />
              </IconButton>
            )}
          </TitleBoxUi>
          <Divider sx={{ mt: 1, mb: 1 }} />

          <SiteDepartmentManagerUi
            siteDepartments={data.getSite.departments}
            departments={data.getAllDepartments}
            getDepts={(depts) => setDepts(depts)}
            edit={edit}
          />

          {edit && (
            <Tooltip title="Assign Departments">
              <Fab
                onClick={assignDepts}
                color="primary"
                sx={{
                  position: "absolute",
                  bottom: 70,
                  right: 16,
                  background: PRIMARY_COLOR,
                }}
                aria-label="edit"
              >
                <Save />
              </Fab>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default SitesDetail;
