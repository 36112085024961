import { useQuery } from "@apollo/client";
import {
  AirplaneTicket,
  AirplaneTicketOutlined,
  ArrowBack,
  FilePresent,
  ListAlt,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActionBtn from "../../components/action-btn/ActionBtn";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { BASE_URL, PRIMARY_COLOR } from "../../utils/constants";
import { promptBox } from "../../utils/helpers";
import {
  GET_QUIZ,
  GET_QUIZ_RESULTS,
  PUBLISH_QUIZ_RESULTS,
} from "../quiz/graphQL";

import fileDownload from "js-file-download";
import { isLoading } from "../../store/cache";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";

const QuizResults = () => {
  const { uid } = useParams();
  const navigate = useNavigate();

  const [results, setResults] = useState([]);

  const { data, loading } = useQuery(GET_QUIZ_RESULTS, {
    variables: {
      uid,
    },
  });

  const { mutation: publishQuizResultsNow } = useGraphQLMutator(
    PUBLISH_QUIZ_RESULTS,
    "publishQuizResults",
    [GET_QUIZ_RESULTS],
    () => {
      isLoading(false);
    },
    `Quiz Results published`,
    () => {
      isLoading(true);
    }
  );

  useEffect(() => {
    if (data) {
      setResults(data.getQuizResults);
      console.log(data.getQuizResults);
    }
  }, [data]);

  const viewResults = (uid, userUid) => {
    navigate(`/quizes/${uid}/user-result`);
  };

  const publishQuizResults = () => {
    promptBox(() => {
      isLoading(true);
      publishQuizResultsNow({
        variables: {
          uid,
        },
      });
    }, `You are about to publish quiz results`);
  };

  const exportExcel = () => {
    promptBox(() => {
      isLoading(true);
      axios
        .get(`${BASE_URL}/quizes/${uid}/results-export-excel`, {
          responseType: "blob",
        })
        .then(function (response) {
          isLoading(false);
          fileDownload(response.data, response.headers.filename);
        });
    }, `You are about to export results to excel`);
  };

  const exportPdf = () => {
    promptBox(() => {
      isLoading(true);
      axios
        .get(`${BASE_URL}/quizes/${uid}/results-export-pdf`, {
          responseType: "blob",
        })
        .then(function (response) {
          isLoading(false);
          fileDownload(response.data, response.headers.filename);
        });
    }, `You are about to export results to pdf`);
  };

  const getScore = (r) => {
    return `${r.correctCount} / ${r.quiz_total_questions}`;
  };

  return (
    <>
      {loading && (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      )}

      {data && (
        <TitleBoxUi
          title={`Quiz Title: ${data.getQuiz.name} | Total Questions: ${data.getQuiz.questions?.length}`}
          icon={<List />}
        >
          <div>
            <Button
              onClick={exportPdf}
              startIcon={<FilePresent />}
              sx={{ mr: 1 }}
              variant="contained"
              color="success"
            >
              Export Pdf
            </Button>
            <Button
              onClick={exportExcel}
              startIcon={<FilePresent />}
              sx={{ mr: 1 }}
              variant="contained"
            >
              Export Excel
            </Button>
            {!data.getQuiz.resultsOut && (
              <Button
                onClick={publishQuizResults}
                startIcon={<AirplaneTicketOutlined />}
                sx={{ mr: 1 }}
                color="warning"
                variant="contained"
              >
                Publish Results
              </Button>
            )}
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                mr: 1,
                background: PRIMARY_COLOR,
                color: "white",
                "&:hover": {
                  color: PRIMARY_COLOR,
                  background: "white",
                },
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        </TitleBoxUi>
      )}

      <Divider sx={{ mt: 1, mb: 1 }} />

      {data && results.length === 0 && (
        <Alert severity="error">No Results found</Alert>
      )}

      {results.length > 0 && (
        <TableContainer component={Paper} elevation={6}>
          <Table aria-label="simple table">
            <TableHead sx={{ background: PRIMARY_COLOR, color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }}>#</TableCell>
                <TableCell sx={{ color: "white" }}>Full Name</TableCell>
                <TableCell sx={{ color: "white" }}>Email</TableCell>
                <TableCell sx={{ color: "white" }}>Submitted Date</TableCell>
                <TableCell sx={{ color: "white" }}>Score</TableCell>
                <TableCell sx={{ color: "white" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((r, i) => (
                <TableRow
                  key={r.uuid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{`${i + 1}`}</TableCell>
                  <TableCell>{`${r.user.fullName}`}</TableCell>
                  <TableCell>{`${r.user.email}`}</TableCell>
                  <TableCell>{`${r.submittedDate}`}</TableCell>
                  <TableCell>{getScore(r)}</TableCell>
                  <TableCell>
                    <ActionBtn
                      icon={<ListAlt color="success" />}
                      onClickIcon={() => viewResults(r.uuid, r.user.uuid)}
                      title="View Results"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default QuizResults;
