import { Box, LinearProgress, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  EmojiEvents,
  FactCheckOutlined,
  ListAltRounded,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GET_DASH_QUIZ_RESULTS, UN_ATTEMPTED_QUIZS } from "../graphQL";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Statistic from "../../../components/statistics/Statistic";
import { showToast } from "../../../utils/helpers";
import DonutChart from "react-donut-chart";
import QuizResultsChart from "../../../components/charts/QuizResultsChart";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import AttempVsUnAttemptChart from "../../../components/charts/AttempVsUnAttemptChart";

function MyQuizes() {
  const [myUnattemptedQuiz, setMyUnattemptedQuiz] = useState(0);
  const [myattemptedQuiz, setMyattemptedQuiz] = useState(0);
  const [myQuizResults, setMyQuizResults] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const { data: unAttempted, loading } = useQuery(UN_ATTEMPTED_QUIZS, {
    variables: {
      attempted: false,
    },
    fetchPolicy: "network-only",
  });

  const { data: attempted } = useQuery(UN_ATTEMPTED_QUIZS, {
    variables: {
      attempted: true,
    },
    fetchPolicy: "network-only",
  });

  const { data: resultsOut } = useQuery(GET_DASH_QUIZ_RESULTS);

  const activateLink = (currentPath) => {
    if (currentPath === location.pathname) {
      return "active";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (unAttempted) {
      setMyUnattemptedQuiz(unAttempted.getMyNewQuizs.length);
    }
  }, [unAttempted]);

  useEffect(() => {
    if (attempted) {
      setMyattemptedQuiz(attempted.getMyNewQuizs.length);
    }
  }, [attempted]);

  useEffect(() => {
    if (resultsOut) {
      setMyQuizResults(resultsOut.getDashMyQuizResults.length);
      if (resultsOut.getDashMyQuizResults.length > 0) {
        showToast(
          `${resultsOut.getDashMyQuizResults.length} quizze(s) with unread results`,
          false,
          {
            position: "bottom-right",
          }
        );
      }
    }
  }, [resultsOut]);

  return (
    <>
      <Paper sx={{ p: 2 }} elevation={0}>
        <div className="row">
          <div
            style={{ cursor: "pointer" }}
            className={`col-4 ${activateLink("/dashboard/un-attempt-quizes")}`}
            onClick={() => navigate("/dashboard/un-attempt-quizes")}
          >
            <Statistic
              count={myUnattemptedQuiz}
              title="My Un-Attempted Quizzes"
              icon={<ListAltRounded color="error" fontSize="large" />}
            />
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={`col-4 ${activateLink("/dashboard/attempt-quizes")}`}
            onClick={() => navigate("/dashboard/attempt-quizes")}
          >
            <Statistic
              count={myattemptedQuiz}
              title="My Attempted Quizzes"
              icon={<FactCheckOutlined color="info" fontSize="large" />}
            />
          </div>

          {myQuizResults > 0 && (
            <div
              style={{ cursor: "pointer" }}
              className={`col-4 ${activateLink("/dashboard/quiz-results")}`}
              onClick={() => navigate("/dashboard/quiz-results")}
            >
              <Statistic
                count={myQuizResults}
                title="My Quizzes"
                icon={<EmojiEvents color="success" fontSize="large" />}
              />
            </div>
          )}
        </div>
        <Box sx={{ mt: 2 }} />
        <div className="row">
          <Outlet />
        </div>
        <div className="row">
          <div className="col-5">
            <Paper sx={{ mt: 0, padding: 2 }} elevation={0}>
              {unAttempted && attempted && (
                <AttempVsUnAttemptChart
                  myattemptedQuiz={myattemptedQuiz}
                  myUnattemptedQuiz={myUnattemptedQuiz}
                />
              )}
              {loading && <LinearProgress />}
            </Paper>
          </div>
          <div className="col-7">
            <Paper sx={{ mt: 1, padding: 1 }} elevation={0}>
              <QuizResultsChart />
            </Paper>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default MyQuizes;
