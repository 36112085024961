import { gql } from "@apollo/client";

export const GET_ALL_SITES = gql`
  query getSites {
    getSites {
      uuid
      name
      description
      departments {
        uuid
        name
      }
    }
  }
`;

export const CREATE_SITE = gql`
  mutation createSite($input: SiteInput!) {
    createSite(input: $input) {
      code
      data {
        uuid
        name
      }
      status
      errorDescription
    }
  }
`;

export const DELETE_SITE = gql`
  mutation deleteSite($uuid: String!) {
    deleteSite(uuid: $uuid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation updateSite($uuid: String!, $input: SiteInput!) {
    updateSite(uuid: $uuid, input: $input) {
      code
      errorDescription
      status
      data {
        uuid
      }
    }
  }
`;

export const GET_SITE = gql`
  query getSite($uuid: String!) {
    getAllDepartments {
      uuid
      name
    }
    getSite(uuid: $uuid) {
      uuid
      name
      description
      departments {
        uuid
        name
      }
    }
  }
`;

export const ASSIGN_DEPARTMENTS = gql`
  mutation assignDepartments($input: AssignDepartmentsInput!) {
    assignDepartments(input: $input) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const GET_PUBLIC_SITES = gql`
  query getPublicSites {
    getPublicSites {
      uuid
      name
      departments {
        name
        uuid
      }
    }
  }
`;
