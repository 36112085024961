import { useQuery } from "@apollo/client";
import { Edit, List } from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading } from "../../../store/cache";
import { GET_NOTIFY_SETTINGS, SAVE_NOTIFY_SETTINGS } from "../graphQL";

const NotificationSettings = () => {
  const { loading, data } = useQuery(GET_NOTIFY_SETTINGS);
  const [updating, setUpdating] = useState(false);

  const { mutation: saveSystemNotification } = useGraphQLMutator(
    SAVE_NOTIFY_SETTINGS,
    "saveSystemNotification",
    [GET_NOTIFY_SETTINGS],
    () => {
      isLoading(false);
      setUpdating(false);
    },
    null,
    () => {}
  );

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const changeNotify = (e, uid) => {
    setUpdating(true);
    isLoading(true);
    saveSystemNotification({
      variables: {
        uid,
        checked: e.target.checked,
      },
    });
  };

  return (
    <>
      <TitleBoxUi title={`Notification Settings`} icon={<Edit />}></TitleBoxUi>
      {loading && <LinearProgress />}
      {updating && <LinearProgress />}

      {data && (
        <Paper sx={{ padding: 2, mt: 2 }} elevation={4}>
          <div className="row">
            {data.getAllSystemNotifications.map((s) => (
              <div key={s.feature} className="col-6">
                <p>
                  <List sx={{ fontSize: 25 }} />
                  {capitalizeFirstLetter(s.feature.toLowerCase())} Notification
                  Settings
                </p>
                <Divider />
                {s.settings.map((s) => (
                  <div key={s.uid} className="col-12">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={s.uid}
                          onChange={(e) => changeNotify(e, s.uid)}
                          defaultChecked={s.checked}
                        />
                      }
                      label={`${s.name} (${s.code})`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Paper>
      )}
    </>
  );
};

export default NotificationSettings;
