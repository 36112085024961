import { Divider, Typography } from "@mui/material";
import { Upload } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import "./Uploader.css";
import { showToastTop } from "../../utils/helpers";

import ImgCrop from "antd-img-crop";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Uploader = ({
  data,
  ext,
  getBaseData,
  getFileExt,
  title = "Upload Question Image",
  qUid = null,
  getQnUploadMeta = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const prefixExt = `data:image/${ext};base64,${data}`;
    if (data) {
      setImageUrl(prefixExt);
    }
  }, [data, ext]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      showToastTop("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      showToastTop("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleBeforeCrop = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      showToastTop("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      showToastTop("Image must smaller than 2MB!");
      return false;
    }

    return isJpgOrPng && isLt2M;
  };

  const submitUpload = ({ file }) => {
    getBase64(file, (url) => {
      setImageUrl(url);
      getBaseData(url);
      getFileExt(file.type.split("/")[1].toUpperCase());
      if (qUid) {
        getQnUploadMeta(qUid, url, file.type.split("/")[1].toUpperCase());
      }
    });
  };

  return (
    <div>
      <Typography>{title}</Typography>

      <Divider sx={{ mb: 2 }} />

      {/* <ImgCrop
        modalWidth={800}
        cropperProps={{
          cropSize: {
            width: 800,
            height: 800,
          },
        }}
        aspect={1}
        mod
        zoom
        rotate
        shape="rect"
        beforeCrop={(e) => handleBeforeCrop(e)}
      > */}
      <Upload
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={(options) => submitUpload(options)}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
              height: 400,
              padding: 5,
              objectFit: "scale-down",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {/* </ImgCrop> */}
    </div>
  );
};

export default Uploader;
