import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const SiteDepartmentManagerUi = ({
  departments,
  siteDepartments,
  getDepts,
  edit,
}) => {
  const [depts, setDepts] = useState([]);

  useEffect(() => {
    setDepts(siteDepartments.map((d) => d.uuid));
  }, [siteDepartments]);

  const checkExistance = (uuid) => {
    const uuids = siteDepartments.map((d) => {
      return d.uuid;
    });
    return uuids.includes(uuid);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      if (!depts.includes(e.target.value)) {
        depts.push(e.target.value);
        setDepts(depts);
        getDepts(depts);
      }
    } else {
      if (depts.includes(e.target.value)) {
        const index = depts.indexOf(e.target.value);
        if (index > -1) {
          depts.splice(index, 1);
          setDepts(depts);
          getDepts(depts);
        }
      }
    }
  };

  return (
    <>
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ ml: 3 }}>
          <div className="row">
            {!edit && siteDepartments.length === 0 ? (
              <>
                <Alert severity="error">No Department assigned yet!</Alert>
              </>
            ) : null}
            {!edit &&
              siteDepartments.map((d, i) => (
                <div key={i} className="col-3">
                  <Paper sx={{ padding: 2, mb: 2 }}>
                    <Typography variant="h6">{d.name}</Typography>
                  </Paper>
                </div>
              ))}
            {departments.map((d, i) => (
              <div key={i} className="col-3">
                {edit && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={d.uuid}
                        onChange={handleChange}
                        defaultChecked={checkExistance(d.uuid)}
                      />
                    }
                    label={`${d.name}`}
                  />
                )}
              </div>
            ))}
          </div>
        </Box>
      </Paper>
    </>
  );
};

export default SiteDepartmentManagerUi;
