import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Login from "./pages/login/Login";
import "react-toastify/dist/ReactToastify.css";
import Auth from "./components/auth/Auth";
import Dashboard from "./pages/dashboard/Dashboard";
import MainLayout from "./components/layout/Layout";
import Roles from "./pages/roles/Roles";
import Users from "./pages/users/Users";
import UserDetail from "./pages/user-detail/UserDetail";
import RoleDetail from "./pages/role-detail/RoleDetail";
import { useEffect } from "react";
import { ACCESS_TOKEN, MY_PERMISSIONS, PRIMARY_COLOR } from "./utils/constants";
import { isLoggedInVar, systemInfoVar, userPermissions } from "./store/cache";
import Departments from "./pages/departments/Departments";
import Sites from "./pages/sites/Sites";
import SitesDetail from "./pages/sites/sites-detail/SitesDetail";
import UserCreate from "./pages/user-create/UserCreate";
import Quiz from "./pages/quiz/Quiz";
import QuizQuestions from "./pages/quiz-questions/QuizQuestions";
import NotFound from "./pages/not-found/NotFound";
import QuizPreview from "./pages/quiz-preview/QuizPreview";
import UnAttemptQuizes from "./pages/dashboard/un-attempt-quizes/UnAttemptQuizes";
import QuizStart from "./pages/quiz-start/QuizStart";
import AttemptQuizes from "./pages/dashboard/attempt-quizes/AttemptQuizes";
import QuizView from "./pages/quiz-view/QuizView";
import QuizResults from "./pages/quiz-results/QuizResults";
import QuizUserResult from "./pages/quiz-user-result/QuizUserResult";
import Settings from "./pages/settings/Settings";
import { useQuery } from "@apollo/client";
import { GET_SYSTEM_INFO } from "./pages/login/graphQL";
import { ActivateUser } from "./pages/users/ActivateUser";
import SystemErrors from "./pages/system-errors/SystemErrors";
import { CircularProgress, LinearProgress } from "@mui/material";
import useIdleTimer from "./hooks/useIdleTimer";
import useLogout from "./hooks/useLogout";
import { showToastTop, warningBox } from "./utils/helpers";
import Profile from "./pages/profile/Profile";
import NoAccess from "./pages/no-access/NoAccess";
import DashQuizResults from "./pages/dashboard/quiz-results/DashQuizResults";
import MyQuizResults from "./pages/my-quiz-results/MyQuizResults";
import { PushTokens } from "./pages/push-tokens/PushTokens";
import PasswordReset from "./pages/password-reset/PasswordReset";

function App() {
  const { data, loading } = useQuery(GET_SYSTEM_INFO);

  useEffect(() => {
    if (data) {
      // localStorage.setItem(
      //   SYSTEM_INFO,
      //   JSON.stringify(data.getSystemInfoSettings)
      // );
      systemInfoVar(data.getSystemInfoSettings);
    }
  }, [data]);

  useEffect(() => {
    const permissions = localStorage.getItem(MY_PERMISSIONS);
    //const systemInfo = localStorage.getItem(SYSTEM_INFO);

    if (permissions) {
      userPermissions(permissions);
    }

    // if (systemInfo) {
    //   systemInfoVar(systemInfo);
    // }
  }, []);

  const { logout } = useLogout();

  useIdleTimer(() => {
    showToastTop(`Session Expired, Login again!`, false, {
      autoClose: false,
      position: "top-center",
    });
    logout();
  });

  return (
    <>
      {loading ? (
        <>
          <LinearProgress sx={{ background: PRIMARY_COLOR }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              backdropFilter: "blur(6 px)",
              padding: "0 10px",
            }}
          >
            <CircularProgress
              sx={{ color: PRIMARY_COLOR }}
              thickness={3}
              size={`10rem`}
            />
          </div>
        </>
      ) : (
        <>
          <Router>
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/login" element={<Login />}></Route>

              <Route
                path="/password-reset/:token"
                element={<PasswordReset />}
              />

              <Route element={<Auth />}>
                <Route element={<MainLayout />}>
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route
                      path="un-attempt-quizes"
                      element={<UnAttemptQuizes />}
                    />
                    <Route path="attempt-quizes" element={<AttemptQuizes />} />
                    <Route path="quiz-results" element={<DashQuizResults />} />
                  </Route>
                  <Route path="/my-results/:uid" element={<MyQuizResults />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/no-access" element={<NoAccess />} />
                  <Route path="/departments" element={<Departments />} />
                  <Route path="/sites" element={<Sites />} />
                  <Route path="/sites/:uuid/view" element={<SitesDetail />} />
                  <Route path="/roles" element={<Roles />} />
                  <Route path="/roles/:uuid/view" element={<RoleDetail />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/save" element={<UserCreate />} />
                  <Route path="/users/:uuid/view" element={<UserDetail />} />
                  <Route path="/system-errors" element={<SystemErrors />} />
                  <Route path="/push-tokens" element={<PushTokens />} />
                  <Route
                    path="/users/:uuid/activate-user"
                    element={<ActivateUser />}
                  />
                  <Route path="/quizes" element={<Quiz />} />
                  <Route
                    path="/quizes/:uid/questions"
                    element={<QuizQuestions />}
                  />
                  <Route
                    path="/quizes/:uid/preview"
                    element={<QuizPreview />}
                  />
                  <Route path="/quizes/:uid/start" element={<QuizStart />} />
                  <Route path="/quizes/:uid/view" element={<QuizView />} />
                  <Route
                    path="/quizes/:uid/results"
                    element={<QuizResults />}
                  />
                  <Route
                    path="/quizes/:uid/user-result"
                    element={<QuizUserResult />}
                  />
                  <Route path="/settings" element={<Settings />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
