import { gql } from "@apollo/client";

export const FRONT_END_RESET_PASSWORD = gql`
  mutation frontEndResetPassword($token: String!, $password: String!) {
    frontEndResetPassword(token: $token, password: $password) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;
