import { gql } from "@apollo/client";

export const SAVE_SYSTEM_TITLE = gql`
  mutation saveSystemTitle($title: String!) {
    saveSystemTitle(title: $title) {
      code
      data {
        uuid
      }
      status
      errorDescription
    }
  }
`;

export const SAVE_SYSTEM_LOGO = gql`
  mutation saveSystemLogo($logo: String!) {
    saveSystemLogo(logo: $logo) {
      code
      data {
        uuid
      }
      status
      errorDescription
    }
  }
`;

export const SAVE_SYSTEM_NOTIFY_EMAIL = gql`
  mutation saveSystemEmail($email: String!) {
    saveSystemEmail(email: $email) {
      code
      data {
        uuid
      }
      status
      errorDescription
    }
  }
`;

export const SAVE_QUIZ_REMINDER_DAYS = gql`
  mutation saveQuizReminderDays($days: Float!) {
    saveQuizReminderDays(days: $days) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const SAVE_PUSH_NOTY = gql`
  mutation savePushNotificationEnabled($enabled: Boolean!) {
    savePushNotificationEnabled(enabled: $enabled) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const SAVE_EQAP = gql`
  mutation saveCanEditQuizAfterPublished($editable: Boolean!) {
    saveCanEditQuizAfterPublished(editable: $editable) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const QUIZ_REMINDER_NOW = gql`
  mutation runQuizReminderNow {
    runQuizReminderNow {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const GET_ALL_REMINDERS = gql`
  query getAllQuizReminders {
    getAllQuizReminders {
      uuid
      quiz {
        name
      }
      totalUserCountReminded
      reminderDate
      userReminders {
        user {
          fullName
          email
          site {
            name
          }
          department {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_QUIZ_REMINDER = gql`
  mutation deleteQuizReminder($uid: String!) {
    deleteQuizReminder(uid: $uid) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const SAVE_SYSTEM_INFO = gql`
  mutation saveSystemInfo($email: String!, $title: String!) {
    saveSystemEmail(email: $email) {
      code
      data {
        uuid
        notifyEmail
      }
      status
      errorDescription
    }
    saveSystemTitle(title: $title) {
      code
      data {
        uuid
        notifyEmail
      }
      status
      errorDescription
    }
  }
`;

export const SAVE_COMPANY_INFO = gql`
  mutation saveCompanyInfo($input: CompanyInfoInput!) {
    saveCompanyInfo(input: $input) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;

export const GET_NOTIFY_SETTINGS = gql`
  query {
    getAllSystemNotifications {
      feature
      settings {
        name
        code
        uid
        checked
      }
    }
  }
`;

export const SAVE_NOTIFY_SETTINGS = gql`
  mutation saveSystemNotification($uid: String!, $checked: Boolean!) {
    saveSystemNotification(uid: $uid, checked: $checked) {
      code
      data {
        uuid
      }
      errorDescription
      status
    }
  }
`;
