import {
  Download,
  FilePresent,
  FileUpload,
  Group,
  Key,
  Save,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import "./User.css";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DataTableUi from "../../components/data-table/DataTableUi";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";
import ActionBtn from "../../components/action-btn/ActionBtn";
import {
  ACTIVATE_USER,
  BLOCK_USER,
  CREATE_USERS,
  DELETE_USER,
  GET_ALL_USERS,
  IMPORT_USERS,
  UPDATE_USER,
} from "./graphQL";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading } from "../../store/cache";
import { promptBox, showToast, showToastTop } from "../../utils/helpers";
import ChangePasswordUi from "../../components/change-password/ChangePasswordUi";
import { useNavigate } from "react-router-dom";
import { BASE_URL, EXCEL_EXT, PRIMARY_COLOR } from "../../utils/constants";
import { useQuery } from "@apollo/client";
import { GET_PUBLIC_SITES } from "../sites/graphQL";
import fileDownload from "js-file-download";
import axios from "axios";

const Users = () => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [depts, setDepts] = useState([]);
  const [selectedDept, setSelectedDept] = useState();

  const { loading, data } = useQuery(GET_PUBLIC_SITES);

  const fileRef = useRef();

  const [importing, setImporting] = useState(false);

  useEffect(() => {
    if (data) {
      setSites(data.getPublicSites);
    }
  }, [data]);

  const [visiblePass, setVisiblePass] = useState(false);

  const [modalTitle, setModalTitle] = useState("Add User");

  const navigate = useNavigate();

  const {
    mutation: createUser,
    submiting,
    visible,
    setVisible,
    setSubmitting,
    setEditing,
    editing,
    setUuid,
    uuid,
    stopLoading,
  } = useGraphQLMutator(CREATE_USERS, "createUser", [GET_ALL_USERS]);

  let formSchema;

  if (!editing) {
    formSchema = Yup.object().shape({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid Email provided"),
      userType: Yup.string()
        .required("User Type is required")
        .default("STUDENT"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords does not match"),
    });
  } else {
    formSchema = Yup.object().shape({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid Email provided"),
      userType: Yup.string()
        .required("User Type is required")
        .default("STUDENT"),
    });
  }

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const { mutation: updateUser } = useGraphQLMutator(
    UPDATE_USER,
    "updateUser",
    [GET_ALL_USERS],
    () => {
      setVisible(false);
      isLoading(false);
    }
  );

  const { mutation: deleteUserMutation } = useGraphQLMutator(
    DELETE_USER,
    "deleteUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
    },
    `Successfully deleted!`
  );

  const { mutation: blockUserMutation } = useGraphQLMutator(
    BLOCK_USER,
    "blockUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
    },
    `Successfully blocked!`
  );

  const { mutation: activateUserMutation } = useGraphQLMutator(
    ACTIVATE_USER,
    "activateUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
    },
    `Successfully activated!`
  );

  const { mutation: importUsers } = useGraphQLMutator(
    IMPORT_USERS,
    "importUsers",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
      setImporting(false);
      fileRef.current.value = "";
    },
    `Successfully imported!`
  );

  // Mutation

  const showModal = () => {
    setVisible(true);
    setEditing(false);
    reset();
    stopLoading();
    setModalTitle(`Add User`);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    if (editing) {
      updateUser({
        variables: {
          uuid: uuid,
          input: {
            ...data,
          },
        },
      });
    } else {
      createUser({
        variables: {
          input: { ...data },
        },
      });
    }
  };

  const editUserInfo = (uuid) => {
    navigate("/users/save", { state: { uuid } });
  };

  const editUser = async ({
    id,
    __typename,
    uuid,
    active,
    createdAt,
    ...user
  }) => {
    setVisible(true);
    setModalTitle("Edit User");
    setEditing(true);
    setUuid(uuid);
    stopLoading();
    Object.keys(user).forEach((k) => {
      setValue(k, user[k]);
    });
  };

  const deleteUser = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteUserMutation({
        variables: {
          uuid,
        },
      });
    });
  };

  const blockUser = (uuid) => {
    promptBox(() => {
      isLoading(true);

      blockUserMutation({
        variables: {
          uuid,
        },
      });
    }, "User will be blocked");
  };

  const activateUser = (uuid) => {
    promptBox(() => {
      isLoading(true);
      activateUserMutation({
        variables: {
          uuid,
        },
      });
    }, "User will be activated");
  };

  const changePassword = (uuid) => {
    setVisiblePass(true);
    setUuid(uuid);
  };

  const viewUserDetail = (uuid) => {
    navigate(`/users/${uuid}/view`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Site",
      key: "site",
      render: (_, rec) => {
        const site = rec.site.name;
        return <Chip size="small" label={site} />;
      },
    },
    {
      title: "Department",
      key: "deptment",
      render: (_, rec) => {
        const dept = rec.department.name;
        return <Chip size="small" label={dept} />;
      },
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <ActionBtn
            title="Edit User Detail"
            onClickIcon={() => editUserInfo(record.uuid)}
            icon={<EditIcon color="info" fontSize="inherit" />}
          />

          <ActionBtn
            title="Delete User Detail"
            onClickIcon={() => deleteUser(record.uuid)}
            icon={<DeleteIcon color="error" fontSize="inherit" />}
          />

          {record.active ? (
            <ActionBtn
              onClickIcon={() => blockUser(record.uuid)}
              icon={<LockOpenIcon color="success" fontSize="inherit" />}
              title="Block User"
            />
          ) : (
            <ActionBtn
              onClickIcon={() => activateUser(record.uuid)}
              icon={<LockIcon color="secondary" fontSize="inherit" />}
              title="Activate User"
            />
          )}

          <ActionBtn
            onClickIcon={() => viewUserDetail(record.uuid)}
            icon={<RemoveRedEyeIcon color="success" fontSize="inherit" />}
            title="View User Detail"
          />

          <ActionBtn
            onClickIcon={() => changePassword(record.uuid)}
            icon={<Key color="warning" fontSize="inherit" />}
            title="Change User Password"
          />
        </Space>
      ),
    },
  ];

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const changeFileHandler = async (event) => {
    const file = event.target.files[0];
    if (file.type !== EXCEL_EXT) {
      showToastTop(`File should be excel format i.e .xlsx only`);
      return;
    }
    const base64file = await toBase64(file);
    isLoading(true);
    setImporting(true);
    importUsers({
      variables: {
        file: base64file,
      },
    });
  };

  const downloadTemplate = () => {
    promptBox(() => {
      isLoading(true);
      axios
        .get(`${BASE_URL}/users/download-list-template`, {
          responseType: "blob",
        })
        .then(function (response) {
          isLoading(false);
          fileDownload(response.data, response.headers.filename);
        });
    }, `You are about to download users import template`);
  };

  const exportExcel = () => {
    promptBox(() => {
      isLoading(true);
      axios
        .get(`${BASE_URL}/users/export-excel`, {
          responseType: "blob",
        })
        .then(function (response) {
          isLoading(false);
          fileDownload(response.data, response.headers.filename);
        });
    }, `You are about to export users to excel`);
  };

  return (
    <>
      <TitleBoxUi title="Manage Users" icon={<Group />}>
        <div>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={changeFileHandler}
            ref={fileRef}
          />

          <Button
            onClick={() => downloadTemplate()}
            variant="contained"
            sx={{ background: "green", mr: 1 }}
            startIcon={<Download />}
          >
            Download Users Template
          </Button>

          <Button
            onClick={exportExcel}
            startIcon={<FilePresent />}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            Export Excel
          </Button>

          <Button
            onClick={() => fileRef.current.click()}
            variant="contained"
            sx={{ background: "blue", mr: 1 }}
            startIcon={<FileUpload />}
          >
            Import Users
          </Button>

          <Button
            onClick={() => navigate("/users/save")}
            variant="contained"
            sx={{ background: PRIMARY_COLOR }}
            startIcon={<Save />}
          >
            Add User
          </Button>
        </div>
      </TitleBoxUi>

      {importing && <LinearProgress />}

      <Paper
        sx={{
          mt: 2,
          padding: 2,
        }}
      >
        <ModalContainerUi
          title={modalTitle}
          visible={visible}
          onCancel={() => {
            setVisible(false);
            reset();
          }}
        >
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              label="Full Name"
              name="fullName"
              {...register("fullName")}
              error={Boolean(errors.fullName)}
              helperText={errors.fullName?.message}
            />

            <TextField
              margin="normal"
              fullWidth
              label="Email Address"
              name="email"
              {...register("email")}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />

            <Controller
              name="userType"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{ mt: 2 }}
                  error={Boolean(errors.userType)}
                >
                  <InputLabel>User Type</InputLabel>
                  <Select name="userType" label="User Type" {...field}>
                    <MenuItem value={`ADMIN`}>ADMIN</MenuItem>
                    <MenuItem value={`STUDENT`}>STUDENT</MenuItem>
                  </Select>
                  <FormHelperText>{errors.userType?.message}</FormHelperText>
                </FormControl>
              )}
            />

            {editing ? null : (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  {...register("password")}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  {...register("confirmPassword")}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                />
              </>
            )}

            <ModalFooter
              loading={submiting}
              onCancel={() => {
                setVisible(false);
                reset();
              }}
            />
          </form> */}
          <TextField
            margin="normal"
            fullWidth
            id="fullName"
            label="Full Name"
            name="fullName"
            {...register("fullName")}
            error={Boolean(errors.fullName)}
            helperText={errors.fullName?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            {...register("email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />

          {sites.length > 0 && (
            <Autocomplete
              sx={{ mt: 1, mb: 1 }}
              options={sites.map((site) => site.name)}
              value={selectedSite}
              onChange={(event, newValue) => {
                setSelectedSite(newValue);
                const site = sites.filter((s) => s.name == newValue)[0];
                setSelectedDept("");
                setDepts(site.departments);
              }}
              renderInput={(params) => (
                <TextField
                  {...register("site")}
                  error={Boolean(errors.site)}
                  helperText={errors.site?.message}
                  {...params}
                  label="Select Site"
                  variant="outlined"
                />
              )}
            />
          )}

          {depts.length > 0 && (
            <Autocomplete
              sx={{ mt: 2, mb: 1 }}
              options={depts.map((dept) => dept.name)}
              value={selectedDept}
              onChange={(event, newValue) => {
                setSelectedDept(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("department")}
                  error={Boolean(errors.department)}
                  helperText={errors.department?.message}
                  label="Select Department"
                  variant="outlined"
                />
              )}
            />
          )}

          <TextField
            margin="normal"
            fullWidth
            label="Password"
            name="password"
            type="password"
            {...register("password")}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            {...register("confirmPassword")}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword?.message}
          />

          <ModalFooter
            loading={submiting}
            onCancel={() => {
              setVisible(false);
              reset();
            }}
          />
        </ModalContainerUi>

        <ChangePasswordUi
          uuid={uuid}
          visiblePass={visiblePass}
          onCancel={(reset) => {
            setVisiblePass(false);
            reset();
          }}
        />

        <DataTableUi
          columns={columns}
          query={GET_ALL_USERS}
          queryName="getAllUsers"
        />
      </Paper>
    </>
  );
};

export default Users;
