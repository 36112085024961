import { useReactiveVar } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, LinearProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { onForgotPasswordClickedVar, systemInfoVar } from "../../store/cache";
import { PRIMARY_COLOR } from "../../utils/constants";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { RESET_PASSWORD } from "../users/graphQL";

export const ForgotPassword = () => {
  const [registering, setRegistering] = useState(false);
  const systemInfo = useReactiveVar(systemInfoVar);

  const { mutation: requestPasswordReset } = useGraphQLMutator(
    RESET_PASSWORD,
    "requestPasswordReset",
    [],
    () => {
      setRegistering(false);
    },
    `You will receive a password recovery link at your email address in a few minutes.`,
    () => {
      onForgotPasswordClickedVar(false);
    },
    true
  );

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email provided"),
  });

  const onSubmit = ({ email }) => {
    setRegistering(true);
    requestPasswordReset({
      variables: {
        email,
      },
    });
  };

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  return (
    <>
      {registering && <LinearProgress />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {systemInfo.logo && (
            <img
              src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
              alt="Logo"
              style={{
                marginTop: 25,
                width: 100,
              }}
            />
          )}
          <p></p>
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            {...register("email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />

          <Button
            type="submit"
            size="large"
            sx={{ width: "100%", backgroundColor: PRIMARY_COLOR, mt: 2, mb: 4 }}
            variant="contained"
          >
            Reset Password
          </Button>
        </div>
      </form>
    </>
  );
};
