import { useQuery } from "@apollo/client";
import { CheckBox, Info } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading } from "../../store/cache";
import { promptBox } from "../../utils/helpers";
import { ACTIVATE_USER, GET_ALL_USERS, GET_USER_INFO } from "./graphQL";

export const ActivateUser = () => {
  const { uuid: uid } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(GET_USER_INFO, {
    variables: {
      uid: uid,
    },
  });

  const { mutation: activateUserMutation } = useGraphQLMutator(
    ACTIVATE_USER,
    "activateUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
    },
    `Successfully activated!`,
    () => {
      navigate("/users");
    }
  );

  const activateUser = () => {
    promptBox(() => {
      isLoading(true);
      activateUserMutation({
        variables: {
          uuid: uid,
        },
      });
    }, `You will active user`);
  };

  return (
    <>
      {data && (
        <>
          <TitleBoxUi icon={<Info />} title={`${data.getUser.fullName}`}>
            <Button
              onClick={() => activateUser()}
              variant="contained"
              sx={{ background: "green", mr: 1 }}
              startIcon={<CheckBox />}
            >
              Activate User
            </Button>
          </TitleBoxUi>
          <Paper sx={{ padding: 1, mb: 3, mt: 3 }}>
            <div className="row">
              <div className="col">
                <h6> Full Name</h6>
                <p>{data.getUser.fullName}</p>
              </div>
              <div className="col">
                <h6>Email</h6>
                <p>{data.getUser.email}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h6> Site</h6>
                <p>{data.getUser.site.name}</p>
              </div>
              <div className="col">
                <h6>Department</h6>
                <p>{data.getUser.department.name}</p>
              </div>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};
