import { FactCheck, PlayArrow } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Select, Space } from "antd";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import {
  GET_MY_UN_ATTEMPTED_QUIZES,
  GET_MY_UN_ATTEMPTED_QUIZES_BY_YEAR,
} from "../../quiz/graphQL";

const UnAttemptQuizes = () => {
  const navigate = useNavigate();

  const [year, setYear] = useState(() => new Date().getFullYear());

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Questions",
      key: "question",
      render: (_, rec) => {
        return <Chip label={rec.questions.length} size="small" />;
      },
    },
    {
      title: "Published",
      dataIndex: "publishedAt",
      key: "publishedAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <Space>
            <ActionBtn
              title="Start Quiz"
              onClickIcon={() => startQuiz(rec.uuid)}
              icon={<PlayArrow color="success" fontSize="inherit" />}
            />
          </Space>
        );
      },
    },
  ];

  const startQuiz = (uid) => {
    navigate(`/quizes/${uid}/start`);
  };

  const getYearsFromYearGLStarted = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    if (startYear > currentYear) {
      return [];
    }
    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };

  const handleChange = (value) => {
    setYear(value);
  };

  const options = useCallback(() => {
    return getYearsFromYearGLStarted().map((y) => {
      return {
        value: y,
        label: y,
      };
    });
  }, []);

  return (
    <>
      <TitleBoxUi title="My Un Attempted Quizes" icon={<FactCheck />}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            gap: 10,
          }}
        >
          <h5>Change Year: </h5>
          <Select
            size="large"
            defaultValue={getYearsFromYearGLStarted()[0]}
            onChange={handleChange}
            style={{
              width: 200,
            }}
            options={options()}
          />
        </div>
      </TitleBoxUi>
      <DataTableUi
        columns={columns}
        query={GET_MY_UN_ATTEMPTED_QUIZES_BY_YEAR}
        vars={{ year: year.toString() }}
        queryName="getMyUnAttemptQuizsByYear"
      />
    </>
  );
};

export default UnAttemptQuizes;
