import { useReactiveVar } from "@apollo/client";
import { Edit, Save } from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading, systemInfoVar } from "../../../store/cache";
import { PRIMARY_COLOR } from "../../../utils/constants";
import { GET_SYSTEM_INFO } from "../../login/graphQL";
import { SAVE_COMPANY_INFO } from "../graphQL";

const CompanySettings = () => {
  const systemInfo = useReactiveVar(systemInfoVar);
  const [updating, setUpdating] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (systemInfo) {
      setValue("companyName", systemInfo.companyName);
      setValue("companyWebsite", systemInfo.companyWebsite);
      setValue("companyEmail", systemInfo.companyEmail);
      setValue("companyTelephone", systemInfo.companyTelephone);
      setValue("companyAddress", systemInfo.companyAddress);
    }
  }, [systemInfo]);

  const { mutation: saveCompanyInfo } = useGraphQLMutator(
    SAVE_COMPANY_INFO,
    "saveCompanyInfo",
    [GET_SYSTEM_INFO],
    () => {
      isLoading(false);
      setUpdating(false);
    },
    null,
    () => {
      isLoading(false);
      setUpdating(false);
    }
  );

  const onSubmit = (data) => {
    setUpdating(true);
    saveCompanyInfo({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  return (
    <>
      <TitleBoxUi
        title={`Company Details Settings`}
        icon={<Edit />}
      ></TitleBoxUi>
      {updating && <LinearProgress />}

      <Paper sx={{ mt: 2, padding: 2 }}>
        <div className="row">
          <div className="col-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="col-12">
                  <Typography>Company Name</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="Company Name"
                    name="title"
                    {...register("companyName", {
                      required: "Company Name is required.",
                    })}
                    error={Boolean(errors.companyName)}
                    helperText={errors.companyName?.message}
                  />
                </div>

                <div className="col-12">
                  <Typography>Company Website</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="Company Website"
                    name="website"
                    {...register("companyWebsite", {
                      required: "Company Website is required.",
                    })}
                    error={Boolean(errors.companyWebsite)}
                    helperText={errors.companyWebsite?.message}
                  />
                </div>

                <div className="col-12" style={{ marginTop: 5 }}>
                  <Typography>Company Email</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="Company Email"
                    name="email"
                    {...register("companyEmail", {
                      required: "Email is required.",
                    })}
                    error={Boolean(errors.companyEmail)}
                    helperText={errors.companyEmail?.message}
                  />
                </div>

                <div className="col-12">
                  <Typography>Company Telephone</Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    focused
                    label="Company Telephone"
                    name="telephone"
                    {...register("companyTelephone")}
                    error={Boolean(errors.companyWebsite)}
                    helperText={errors.companyWebsite?.message}
                  />
                </div>

                <div className="col-12"></div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    startIcon={<Save />}
                    variant="filled"
                    sx={{
                      background: PRIMARY_COLOR,
                      color: "white",
                      "&:hover": {
                        color: PRIMARY_COLOR,
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-6">
            <Typography>Company Address</Typography>
            <TextField
              multiline
              rows={3}
              margin="normal"
              fullWidth
              focused
              label="Company Address"
              name="address"
              {...register("companyAddress", {
                required: "Company Address is required.",
              })}
              error={Boolean(errors.companyAddress)}
              helperText={errors.companyAddress?.message}
            />
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CompanySettings;
