import { EmojiEvents, FactCheck, RemoveRedEye } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import { GET_MY_ATTEMPTED_QUIZES } from "../../quiz/graphQL";

const AttemptQuizes = () => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Questions",
      key: "question",
      render: (_, rec) => {
        return <Chip label={rec.questions.length} size="small" />;
      },
    },
    {
      title: "Published",
      dataIndex: "publishedAt",
      key: "publishedAt",
    },
    {
      title: "Submited Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <Space>
            <ActionBtn
              title="View Quiz"
              onClickIcon={() => viewMyQuiz(rec.uuid)}
              icon={<RemoveRedEye color="info" fontSize="inherit" />}
            />
            {rec.checkedResultsOut && (
              <ActionBtn
                title="View Quiz Results"
                onClickIcon={() => viewMyQuizResults(rec.uuid)}
                icon={<EmojiEvents color="success" fontSize="inherit" />}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const viewMyQuiz = (uid) => {
    navigate(`/quizes/${uid}/view`);
  };

  const viewMyQuizResults = (uid) => {
    navigate(`/my-results/${uid}`);
  };

  return (
    <>
      <TitleBoxUi title="My Attempted Quizes" icon={<FactCheck />}></TitleBoxUi>
      <DataTableUi
        columns={columns}
        query={GET_MY_ATTEMPTED_QUIZES}
        queryName="getMyAttemptQuizs"
      />
    </>
  );
};

export default AttemptQuizes;
