import { Divider, Paper } from "@mui/material";
import "./Dashboard.css";

import HasPermissionUi from "../../components/has-permission/HasPermissionUi";
import MyQuizes from "./my-quizes/MyQuizes";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import {
  AssignmentIndRounded,
  AssignmentOutlined,
  BookOnlineOutlined,
  DashboardCustomizeRounded,
} from "@mui/icons-material";
import Widget from "../../components/widget/Widget";
import {
  GET_DASH_ALL_USERS,
  GET_DASH_NEW_USERS,
  GET_DASH_QUIZES,
} from "./graphQL";

function Dashboard() {
  return (
    <>
      <TitleBoxUi
        title={`Dashboard`}
        icon={<DashboardCustomizeRounded />}
      ></TitleBoxUi>
      <Paper sx={{ padding: 2, mt: 1 }} elevation={0}>
        <div className="row">
          <HasPermissionUi permission={"VIEW_DASH_NEW_USERS"}>
            <div className="col-3">
              <Widget
                title={`New Users`}
                queryName="getDashNewUsers"
                query={GET_DASH_NEW_USERS}
              />
            </div>
          </HasPermissionUi>
          <HasPermissionUi permission={"VIEW_DASH_ALL_USERS"}>
            <div className="col-3">
              <Widget
                title={`Users`}
                queryName="getDashAllUsers"
                query={GET_DASH_ALL_USERS}
              />
            </div>
          </HasPermissionUi>
          <HasPermissionUi permission={"VIEW_DASH_QUIZES"}>
            <div className="col-3">
              <Widget
                icon={<AssignmentOutlined style={{ fontSize: 120 }} />}
                title={`Quizzes`}
                queryName="getDashQuizes"
                query={GET_DASH_QUIZES}
              />
            </div>
          </HasPermissionUi>
        </div>
      </Paper>

      <Divider sx={{ mt: 1, mb: 2 }} />
      <HasPermissionUi permission={"MY_QUIZES"}>
        <MyQuizes />
      </HasPermissionUi>
    </>
  );
}

export default Dashboard;
