import { ArrowBack, PlaylistAddCheck, Upload } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Divider,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { PRIMARY_COLOR } from "../../utils/constants";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PUBLIC_SITES, UPDATE_SITE } from "../sites/graphQL";
import ModalFooter from "../../components/modal-footer/ModalFooter";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";

import {
  CREATE_USERS,
  GET_ALL_USERS,
  GET_USER_INFO,
  UPDATE_USER,
} from "../users/graphQL";
import { isLoading } from "../../store/cache";
import {
  getValue,
  setSelectionRange,
} from "@testing-library/user-event/dist/utils";
import { useRef } from "react";

const UserCreate = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const deptRef = useRef();

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [depts, setDepts] = useState([]);
  const [selectedDept, setSelectedDept] = useState();

  const { loading, data } = useQuery(GET_PUBLIC_SITES);

  const [user, setUser] = useState();

  const [getUserInfo, { data: dt }] = useLazyQuery(GET_USER_INFO);

  useEffect(() => {
    if (data) {
      setSites(data.getPublicSites);
    }
  }, [data]);

  const {
    mutation: createUser,
    submiting,
    setSubmitting,
    setEditing,
    editing,
    setUuid,
    uuid,
    stopLoading,
  } = useGraphQLMutator(
    CREATE_USERS,
    "createUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
      setSubmitting(false);
    },
    null,
    () => {
      navigate(-1);
    }
  );

  const getSiteUid = (site) => {
    const ss = sites.filter((s) => s.name === site)[0];
    return ss.uuid;
  };

  const getDeptUid = (dept) => {
    console.log(depts);
    const dd = depts.filter((d) => d.name === dept)[0];
    return dd.uuid;
  };

  useEffect(() => {
    if (state) {
      const { uuid: uid } = state;
      if (uid) {
        setUuid(uid);
        setEditing(true);
        getUserInfo({
          variables: {
            uid,
          },
        });
      }
    }
  }, [state]);

  const { mutation: updateUser, submiting: esubmiting } = useGraphQLMutator(
    UPDATE_USER,
    "updateUser",
    [GET_ALL_USERS],
    () => {
      isLoading(false);
    },
    null,
    () => {
      navigate(-1);
    }
  );

  let formSchema;

  if (!editing) {
    formSchema = Yup.object().shape({
      fullName: Yup.string().required("Full Name is required"),
      site: Yup.string().required("Site is required"),
      department: Yup.string().required("Department is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid Email provided"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords does not match"),
    });
  } else {
    formSchema = Yup.object().shape({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid Email provided"),
      site: Yup.string().required("Site is required"),
      department: Yup.string().required("Department is required"),
    });
  }

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    control,
    setValue,
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  if (dt) {
    const {
      id,
      __typename,
      uuid,
      active,
      createdAt,
      site,
      roles,
      department,
      ...user
    } = dt.getUser;
    if (user) {
      Object.keys(user).forEach((k) => {
        setValue(k, user[k]);
      });
      setValue("site", site.name);
      setValue("department", department.name);
      deptRef.current.value = department.uuid;
    }
  }

  const onSubmit = async (data) => {
    setSubmitting(true);
    isLoading(true);

    const { site, department, ...others } = data;

    let siteUid, departmentUid;

    if (editing) {
      const updateData = {
        fullName: data.fullName,
        email: data.email,
        siteUid: selectedSite
          ? getSiteUid(selectedSite)
          : getSiteUid(getValues("site")),
        departmentUid: selectedDept
          ? getDeptUid(selectedDept)
          : deptRef.current.value,
      };
      updateUser({
        variables: {
          uuid: uuid,
          input: {
            ...updateData,
          },
        },
      });
    } else {
      if (site && department) {
        siteUid = getSiteUid(site);

        if (deptRef.current.value) {
          departmentUid = deptRef.current.value;
        } else {
          departmentUid = getDeptUid(department);
        }
      }

      const newData = {
        siteUid,
        departmentUid,
        ...others,
      };

      createUser({
        variables: {
          input: { ...newData },
        },
      });
    }
  };

  return (
    <>
      <TitleBoxUi
        title={`${editing ? "Edit User" : "Create New User"}`}
        icon={<PlaylistAddCheck />}
      >
        <div>
          <IconButton
            sx={{ color: PRIMARY_COLOR }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </IconButton>
        </div>
      </TitleBoxUi>
      <Divider sx={{ mb: 1, mt: 1 }} />
      <Paper sx={{ padding: 2 }} elevation={2}>
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" ref={deptRef} />
              <TextField
                focused={editing}
                margin="normal"
                fullWidth
                id="fullName"
                label="Full Name"
                name="fullName"
                {...register("fullName")}
                error={Boolean(errors.fullName)}
                helperText={errors.fullName?.message}
              />
              <TextField
                margin="normal"
                fullWidth
                focused={editing}
                label="Email Address"
                name="email"
                {...register("email")}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
              {editing && (
                <Chip size="small" color="error" label={getValues("site")} />
              )}
              <Autocomplete
                sx={{ mt: 1, mb: 1 }}
                options={sites.map((site) => site.name)}
                value={selectedSite}
                onChange={(event, newValue) => {
                  setSelectedSite(newValue);
                  const site = sites.filter((s) => s.name == newValue)[0];
                  setSelectedDept("");
                  setDepts(site.departments);
                }}
                renderInput={(params) => (
                  <TextField
                    {...register("site")}
                    error={Boolean(errors.site)}
                    helperText={errors.site?.message}
                    {...params}
                    label="Select Site"
                    variant="outlined"
                  />
                )}
              />
              {editing && (
                <Chip
                  size="small"
                  color="error"
                  label={getValues("department")}
                />
              )}

              <Autocomplete
                sx={{ mt: 2, mb: 1 }}
                options={depts.map((dept) => dept.name)}
                value={selectedDept}
                onChange={(event, newValue) => {
                  setSelectedDept(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("department")}
                    error={Boolean(errors.department)}
                    helperText={errors.department?.message}
                    label="Select Department"
                    variant="outlined"
                  />
                )}
              />

              {!editing && (
                <>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    {...register("password")}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    {...register("confirmPassword")}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                  />
                </>
              )}

              <ModalFooter
                loading={editing ? esubmiting : submiting}
                onCancel={() => {
                  reset();
                }}
              />
            </form>
          </div>
          <div className="col-md-8"></div>
        </div>
      </Paper>
    </>
  );
};

export default UserCreate;
