import "./Layout.css";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Typography } from "antd";
import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { isLoggedInVar, systemInfoVar } from "../../store/cache";
import { useApolloClient, useQuery, useReactiveVar } from "@apollo/client";
import {
  ACCESS_TOKEN,
  MY_PERMISSIONS,
  PRIMARY_COLOR,
} from "../../utils/constants";
import AppLoader from "../loaders/AppLoader";
import Sidebar from "../sidebar/Sidebar";
import avatar from "../../assets/avatar.png";
import { AccountBox, Key } from "@mui/icons-material";
import ChangePasswordUi from "../change-password/ChangePasswordUi";
import { GET_CURRENT_USER } from "../../pages/users/graphQL";

const { Header, Sider, Content } = Layout;

const settings = [
  {
    key: "profile",
    label: "Profile",
    icon: <AccountBox />,
  },
  {
    key: "change-password",
    label: "Change Password",
    icon: <Key />,
  },
  {
    key: "logout",
    label: "Logout",
    icon: <ExitToAppIcon />,
  },
];

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [chpassModal, setChpassModal] = useState(false);

  const client = useApolloClient();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const systemInfo = useReactiveVar(systemInfoVar);

  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_CURRENT_USER);

  const handleCloseUserMenu = (key) => {
    if (key === "logout") {
      client.clearStore();
      client.cache.gc();
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(MY_PERMISSIONS);
      // Set the logged-in status to false
      isLoggedInVar(false);
    }

    if (key === "home") {
      navigate("/");
    }

    if (key === "profile") {
      navigate("/profile");
    }

    if (key === "change-password") {
      setChpassModal(true);
    }

    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const logoMin = (
    <img
      src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
      alt="Logo"
      style={{
        marginTop: 25,
        width: 50,
        marginBottom: 30,
      }}
    />
  );

  const logoMax = (
    <img
      src={`${systemInfo.logoPrefix}${systemInfo.logo}`}
      alt="Logo"
      style={{
        marginTop: 25,
        width: 100,
        marginBottom: 30,
      }}
    />
  );

  return (
    <AppLoader color={PRIMARY_COLOR}>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div
            className="logo"
            onClick={() => navigate("/home")}
            style={{ cursor: "pointer" }}
          >
            {collapsed
              ? systemInfo.logo && logoMin
              : systemInfo.logo && logoMax}
          </div>
          <Sidebar />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              paddingLeft: 10,
            }}
          >
            <>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </>

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={avatar} />
            </IconButton>

            <MuiMenu
              sx={{ mt: "30px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.key}
                  onClick={() => handleCloseUserMenu(setting.key)}
                >
                  <ListItemIcon>{setting.icon}</ListItemIcon>
                  <ListItemText>{setting.label}</ListItemText>
                </MenuItem>
              ))}
            </MuiMenu>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 14,
              minHeight: 280,
              height: "100vh",
            }}
          >
            {data && (
              <ChangePasswordUi
                onCancel={() => setChpassModal(false)}
                visiblePass={chpassModal}
                uuid={data.getCurrentUserInfo.uuid}
              />
            )}

            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </AppLoader>
  );
};

export default MainLayout;
