import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfoOutlined } from "@mui/icons-material";
import { Chip, LinearProgress, Paper } from "@mui/material";
import { Divider, Typography } from "antd";
import React from "react";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import { GET_CURRENT_USER } from "../users/graphQL";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormModalContainerUi from "../../components/form-modal-container/FormModalContainerUi";
import ModalContainerUi from "../../components/modal-container/ModalContainerUi";

const Profile = () => {
  const { loading, data } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  return (
    <>
      <TitleBoxUi
        title={`Profile Information`}
        icon={<InfoOutlined />}
      ></TitleBoxUi>
      {loading && <LinearProgress />}

      {data && (
        <Paper sx={{ mt: 2, padding: 2 }}>
          <div className="row">
            <div className="col-3">
              <Typography variant="h6">Full Name</Typography>
              <Divider />
              <Chip label={data.getCurrentUserInfo.fullName} />
            </div>
            <div className="col-3">
              <Typography variant="h6">Email</Typography>
              <Divider />
              <Chip label={data.getCurrentUserInfo.email} />
            </div>
            <div className="col-3">
              <Typography variant="h6">Site</Typography>
              <Divider />
              <Chip label={data.getCurrentUserInfo.site?.name} />
            </div>
            <div className="col-3">
              <Typography variant="h6">Department</Typography>
              <Divider />
              <Chip label={data.getCurrentUserInfo.department?.name} />
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};

export default Profile;
