import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import {
  HomeOutlined,
  CheckOutlined,
  UsergroupAddOutlined,
  ApartmentOutlined,
  ReadFilled,
  FileTextFilled,
  SettingFilled,
  BellFilled,
  AlertOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userPermissions } from "../../store/cache";

const items = [
  {
    key: `dashboard`,
    icon: <HomeOutlined />,
    label: "Dashboard",
    path: "/dashboard",
    permission: "VIEW_DASHBOARD",
  },
  {
    key: `quiz`,
    icon: <ReadFilled />,
    label: "Quizzes",
    path: "/quizes",
    permission: "VIEW_QUIZES",
  },
  {
    key: `myQuiz`,
    icon: <ReadFilled />,
    label: "My Quizzes",
    path: "/dashboard/un-attempt-quizes",
    permission: "MY_QUIZES",
  },
  {
    key: `reminder`,
    icon: <BellFilled />,
    label: "Reminders",
    path: "/reminders",
    permission: "VIEW_REMINDERS",
  },
  {
    key: `reports`,
    icon: <FileTextFilled />,
    label: "Reports",
    path: "/reports",
    permission: "VIEW_REPORTS",
  },
  {
    key: `roles`,
    icon: <CheckOutlined />,
    label: "Roles",
    path: "/roles",
    permission: "VIEW_ROLES",
  },
  {
    key: `users`,
    icon: <UsergroupAddOutlined />,
    label: "Users",
    path: "/users",
    permission: "VIEW_USERS",
  },
  {
    key: `sites`,
    icon: <ApartmentOutlined />,
    label: "Sites",
    path: "/sites",
    permission: "VIEW_SITES",
  },
  {
    key: `departments`,
    icon: <ApartmentOutlined />,
    label: "Departments",
    path: "/departments",
    permission: "VIEW_DEPARTMENTS",
  },
  {
    key: `settings`,
    icon: <SettingFilled />,
    label: "Settings",
    path: "/settings",
    permission: "VIEW_SETTINGS",
  },
  {
    key: `system-errors`,
    icon: <AlertOutlined />,
    label: "System Errors",
    path: "/system-errors",
    permission: "VIEW_SETTINGS",
  },
];

const Sidebar = () => {
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname.split("/")[1] === ""
      ? "home"
      : location.pathname.split("/")[1]
  );

  useEffect(() => {
    setCurrentPath(
      location.pathname.split("/")[1] === ""
        ? "home"
        : location.pathname.split("/")[1]
    );
  }, [location, currentPath]);

  const permissions = useReactiveVar(userPermissions);

  return (
    <>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${currentPath}`]}>
        {items
          .filter((i) => {
            return permissions.includes(i.permission);
          })
          .map((i) => (
            <Menu.Item key={`${i.key}`}>
              <Link to={`${i.path}`}>
                {i.icon}
                <span>{`${i.label}`}</span>
              </Link>
            </Menu.Item>
          ))}
      </Menu>
    </>
  );
};

export default Sidebar;
