import { useQuery } from "@apollo/client";
import {
  Article,
  Delete,
  FileCopyOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { Chip, IconButton, LinearProgress } from "@mui/material";
import { Space } from "antd";
import React, { useState } from "react";
import ActionBtn from "../../../components/action-btn/ActionBtn";
import DataTableUi from "../../../components/data-table/DataTableUi";
import ModalContainerUi from "../../../components/modal-container/ModalContainerUi";
import TitleBoxUi from "../../../components/title-box/TitleBoxUi";
import useGraphQLMutator from "../../../hooks/useGraphQLMutator";
import { isLoading } from "../../../store/cache";
import { promptBox } from "../../../utils/helpers";
import { DELETE_FCM_ERROR, GET_FCM_ERRORS } from "../graphQL";

export const SystemFCMError = () => {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();

  const { loading, data } = useQuery(GET_FCM_ERRORS);

  const { mutation: deleteFcmError } = useGraphQLMutator(
    DELETE_FCM_ERROR,
    "deleteFcmError",
    [GET_FCM_ERRORS],
    () => {
      isLoading(false);
    }
  );

  const deleteLog = (uuid) => {
    promptBox(() => {
      isLoading(true);
      deleteFcmError({
        variables: {
          uuid,
        },
      });
    });
  };

  const viewError = (errorMessage) => {
    setShowError(true);
    setError(errorMessage);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Error Message",
      dataIndex: "description",
      render: (_, rec) => {
        return (
          <IconButton onClick={() => viewError(rec.description)}>
            <Article color="primary" />
          </IconButton>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, rec) => {
        return (
          <Space>
            <ActionBtn
              title="Delete Log"
              onClickIcon={() => deleteLog(rec.uuid)}
              icon={<Delete color="error" fontSize="inherit" />}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalContainerUi
        title={`FCM Error`}
        top
        onCancel={() => setShowError(false)}
        visible={showError}
      >
        {error}
      </ModalContainerUi>

      <TitleBoxUi title={`Firebase Cloud Massing Errors`}></TitleBoxUi>
      {loading && <LinearProgress />}
      {data && (
        <DataTableUi
          columns={columns}
          query={GET_FCM_ERRORS}
          queryName="getFcmErrors"
        />
      )}
    </>
  );
};
